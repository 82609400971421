/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { InputField } from '../../settings/fileUploaderPage/fileUploader.page.styled';
import SubmitButtonGroup from './submitButtonGroup';
import DropdownOne from '../../../components/dropdownone';
import { validateContainerName } from '../../../utils/functions';
import { STORAGE_SOURCE } from '../../../constants/dataSources';
import { Regions } from '../../../constants/gcpRegions';

const GcpGcsForm = (props) => {
  const [inputValues, setInputValues] = useState({
    name: '',
    storageConnectionId: props.storageConfigId,
    type: props.storageContainer,
    sourceType: STORAGE_SOURCE,
    params: {
      bucketName: '',
      region: Regions[0].value,
    },
  });

  const [validationErrorLabel, setValidationErrorLabel] = useState('');

  const handleInputChange = (fieldName, value) => {
    setInputValues((prevState) => ({
      ...prevState,
      ...(fieldName.includes('.')
        ? { params: { ...prevState.params, [fieldName.split('.')[1]]: value } }
        : { [fieldName]: value }),
    }));
  };

  const clearValues = () => {
    setInputValues({
      name: '',
      storageConnectionId: props.storageConfigId,
      type: props.storageContainer,
      params: {
        bucketName: '',
        region: '',
      },
    });
  };

  const handleBucketNameBlur = () => {
    const validate = validateContainerName(inputValues.params.bucketName, t);
    setValidationErrorLabel(validate);
  };

  useEffect(() => {
    if (props.storageConfigId) {
      setInputValues((prevState) => ({
        ...prevState,
        storageConnectionId: props?.storageConfigId,
      }));
    }
  }, [props.storageConfigId]);

  const { t } = useTranslation();
  return (
    <>
      <InputField
        size="small"
        required
        fullWidth
        name="name"
        id="outlined-required"
        label={t('fileUploaderPage.storageConfiguration.newSourcePopover.name')}
        value={inputValues.name}
        onChange={(e) => handleInputChange('name', e.target.value)}
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        fullWidth
        required
        name="bucketName"
        id="outlined-required"
        label={t(
          'fileUploaderPage.storageConfiguration.newSourcePopover.bucketName',
        )}
        value={inputValues.params.bucketName}
        onChange={(e) => handleInputChange('params.bucketName', e.target.value)}
        onBlur={handleBucketNameBlur}
        helperText={validationErrorLabel}
        error={validationErrorLabel != '' ? true : false}
      />
      <Box sx={{ marginTop: 2 }}>
        <DropdownOne
          onChange={(e) => handleInputChange('params.region', e)}
          defaultValue={Regions[0].value}
          label={t(
            'fileUploaderPage.storageConfiguration.newSourcePopover.region',
          )}
          value={inputValues.params.region}
          items={Regions}
        ></DropdownOne>
      </Box>
      <SubmitButtonGroup
        data={inputValues}
        onClear={clearValues}
        errorMessage={validationErrorLabel}
      />
    </>
  );
};

GcpGcsForm.propTypes = {
  storageContainer: PropTypes.string,
  storageConfigId: PropTypes.string,
};

export default GcpGcsForm;

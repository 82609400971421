import { Box, styled, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  margin: '30px 0 10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  width: '25%',
  marginLeft: 10,
}));

export const LoadingButtonStyled = styled(LoadingButton)(({ theme }) => ({
  width: '25%',
  marginLeft: 10,
}));

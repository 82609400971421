/**
 * Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 *
 */

export const Regions = [
  { label: 'South Carolina (us-east1)', value: 'us-east1' },
  { label: 'Iowa (us-central1)', value: 'us-central1' },
  { label: 'Northern Virginia (us-east4)', value: 'us-east4' },
  { label: 'Oregon (us-west1)', value: 'us-west1' },
  { label: 'Los Angeles (us-west2)', value: 'us-west2' },
  { label: 'Salt Lake City (us-west3)', value: 'us-west3' },
  { label: 'Las Vegas (us-west4)', value: 'us-west4' },
  {
    label: 'Montréal (northamerica-northeast1)',
    value: 'northamerica-northeast1',
  },
  {
    label: 'Toronto (northamerica-northeast2)',
    value: 'northamerica-northeast2',
  },
  { label: 'São Paulo (southamerica-east1)', value: 'southamerica-east1' },
  { label: 'Santiago (southamerica-west1)', value: 'southamerica-west1' },
  { label: 'London (europe-west2)', value: 'europe-west2' },
  { label: 'Frankfurt (europe-west3)', value: 'europe-west3' },
  { label: 'Netherlands (europe-west4)', value: 'europe-west4' },
  { label: 'Finland (europe-north1)', value: 'europe-north1' },
  { label: 'Warsaw (europe-central2)', value: 'europe-central2' },
  { label: 'Hong Kong (asia-east2)', value: 'asia-east2' },
  { label: 'Tokyo (asia-northeast1)', value: 'asia-northeast1' },
  { label: 'Osaka (asia-northeast2)', value: 'asia-northeast2' },
  { label: 'Seoul (asia-northeast3)', value: 'asia-northeast3' },
  { label: 'Mumbai (asia-south1)', value: 'asia-south1' },
  { label: 'Delhi (asia-south2)', value: 'asia-south2' },
  { label: 'Singapore (asia-southeast1)', value: 'asia-southeast1' },
  { label: 'Jakarta (asia-southeast2)', value: 'asia-southeast2' },
  { label: 'Sydney (australia-southeast1)', value: 'australia-southeast1' },
  { label: 'Melbourne (australia-southeast2)', value: 'australia-southeast2' },
  { label: 'Johannesburg (africa-south1)', value: 'africa-south1' },
  { label: 'Tel Aviv (me-west1)', value: 'me-west1' },
  { label: 'Doha (me-central1)', value: 'me-central1' },
];

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { Add, Delete, OfflinePin } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import {
  ButtonWithMargin,
  ButtonsContainer2,
  PageHeader,
  PageHeaderContainer,
} from './form.styled';

const translationJSONPrefix = `formComponent.header`;

const Header = ({
  header = '',
  addButtonLabel,
  deleteButton,
  deleteButtonDisabled,
  deleteButtonOnClick,
  addButtonOnClick,
  addButtonDisabled,
  isDeleteLoading,
  defaultButtonOnClick,
  defaultButtonDisabled = true,
  defaultButton = false,
  isDefaultLoading = false,
}) => {
  const { t } = useTranslation();

  return (
    <PageHeaderContainer>
      <PageHeader>{header}</PageHeader>
      <ButtonsContainer2>
        {defaultButton && (
          <ButtonWithMargin
            variant="outlined"
            startIcon={<OfflinePin />}
            onClick={defaultButtonOnClick}
            disabled={defaultButtonDisabled}
            loading={isDefaultLoading}
          >
            {t(`${translationJSONPrefix}.default`)}
          </ButtonWithMargin>
        )}

        {deleteButton && (
          <ButtonWithMargin
            variant="outlined"
            startIcon={<Delete />}
            onClick={deleteButtonOnClick}
            disabled={deleteButtonDisabled}
            loading={isDeleteLoading}
          >
            {t(`${translationJSONPrefix}.delete`)}
          </ButtonWithMargin>
        )}
        {addButtonLabel && (
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={addButtonOnClick}
            disabled={addButtonDisabled}
          >
            {addButtonLabel}
          </Button>
        )}
      </ButtonsContainer2>
    </PageHeaderContainer>
  );
};

Header.propTypes = {
  header: PropTypes.string,
  addButtonLabel: PropTypes.string,
  deleteButton: PropTypes.bool,
  deleteButtonDisabled: PropTypes.bool,
  deleteButtonOnClick: PropTypes.func,
  addButtonOnClick: PropTypes.func,
  addButtonDisabled: PropTypes.bool,
  isDeleteLoading: PropTypes.bool,
  defaultButtonOnClick: PropTypes.func,
  defaultButtonDisabled: PropTypes.bool,
  defaultButton: PropTypes.bool,
  isDefaultLoading: PropTypes.bool,
};

export default Header;

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useState } from 'react';
import {
  Box,
  Autocomplete,
  TextField,
  Chip,
  Switch,
  MenuItem,
  Select,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  InputAdornment,
  Radio,
  IconButton,
} from '@mui/material';
import {
  HelpOutline,
  Restore,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  ButtonStyled,
  ButtonsContainer,
  ErrorTextStyled,
  InputLabel,
  LoadingButtonStyled,
  SliderStyled,
  ToogleContainerStyled,
  TextFieldStyled,
  PasswordButton,
} from './form.styled';

const translationJSONPrefix = 'formComponent';

export const FieldLabel = ({ label, tooltipTitle, ...props }) => {
  return (
    <InputLabel>
      {`${label} `}
      {tooltipTitle && (
        <Box component="span">
          <Tooltip
            {...props}
            title={tooltipTitle}
            arrow
            placement="right"
            style={{ fontSize: 20 }}
          >
            <HelpOutline style={{ fontSize: 15 }} />
          </Tooltip>
        </Box>
      )}
    </InputLabel>
  );
};

export const ErrorText = ({ errors, name, touched }) => {
  if (errors[name] && touched[name])
    return <ErrorTextStyled>{errors[name]}</ErrorTextStyled>;

  return <></>;
};

export const ToogleContainer = ({
  label,
  name,
  values,
  setFieldValue,
  errors,
  touched,
  tooltipTitle,
  width,
  ...props
}) => {
  return (
    <ToogleContainerStyled style={{ width }}>
      <FieldLabel label={label} tooltipTitle={tooltipTitle} />
      <Switch
        {...props}
        name={name}
        checked={values[name]}
        onChange={(e) => setFieldValue(name, e.target.checked)}
      />
      <ErrorText errors={errors} name={name} touched={touched} />
    </ToogleContainerStyled>
  );
};

export const AppRadioGroup = ({
  label,
  style,
  name,
  values,
  setFieldValue,
  errors,
  touched,
  tooltipTitle,
  width,
  options,
  ...props
}) => {
  return (
    <Box style={style}>
      <FieldLabel label={label} tooltipTitle={tooltipTitle} />
      <RadioGroup
        {...props}
        name={name}
        value={values[name]}
        onChange={(e) => setFieldValue(name, e.target.value)}
      >
        {options.map((o, i) => (
          <FormControlLabel
            key={i}
            value={o?.value}
            control={<Radio />}
            label={o?.label}
          />
        ))}
      </RadioGroup>
      <ErrorText errors={errors} name={name} touched={touched} />
    </Box>
  );
};

export const AppTextField = ({
  style,
  label,
  name,
  placeholder = '',
  value = '',
  values,
  setFieldValue,
  errors,
  touched,
  tooltipTitle,
  onChange,
  readOnly = false,
  isPasswordField = false,
  ...props
}) => {
  const [showCredentials, setShowCredentials] = useState(false);

  const handleClickShowPassword = () => setShowCredentials((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box style={style}>
      <FieldLabel label={label} tooltipTitle={tooltipTitle} />
      <TextFieldStyled
        type={isPasswordField && !showCredentials ? 'password' : 'text'}
        readOnly={readOnly}
        {...props}
        name={name}
        fullWidth
        placeholder={placeholder}
        value={values ? values[name] : value}
        onChange={
          onChange ? onChange : (e) => setFieldValue(name, e.target.value)
        }
        InputProps={{
          readOnly: readOnly,
          endAdornment: isPasswordField ? (
            <InputAdornment position="end">
              <PasswordButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showCredentials ? <VisibilityOff /> : <Visibility />}
              </PasswordButton>
            </InputAdornment>
          ) : null,
        }}
      />
      {(errors || touched) && (
        <ErrorText errors={errors} name={name} touched={touched} />
      )}
    </Box>
  );
};

export const AppSelectField = ({
  style,
  tooltipTitle,
  name,
  placeholder = '',
  value = '',
  values,
  label,
  errors,
  touched,
  onChange,
  setFieldValue,
  menuData = [],
  ...props
}) => {
  return (
    <Box style={style}>
      <FieldLabel label={label} tooltipTitle={tooltipTitle} />
      <Select
        {...props}
        fullWidth
        placeholder={placeholder}
        name={name}
        value={value || values[name]}
        onChange={onChange || ((e) => setFieldValue(name, e.target.value))}
      >
        {menuData.map((md, i) => (
          <MenuItem key={i} value={md?.value}>
            {md?.label}
          </MenuItem>
        ))}
      </Select>
      <ErrorText errors={errors} name={name} touched={touched} />
    </Box>
  );
};

export const AppAutocompleteField = ({
  style,
  label,
  tooltipTitle,
  options,
  errors,
  touched,
  setFieldValue,
  values,
  name,
  placeholder,
  renderTags,
  onChange,
  ...props
}) => {
  return (
    <Box style={style}>
      <FieldLabel label={label} tooltipTitle={tooltipTitle} />
      <Autocomplete
        {...props}
        multiple
        options={options}
        freeSolo
        variant="contained"
        renderTags={
          renderTags ||
          ((value, getTagProps) =>
            value.map((option, index) => (
              <Chip key={index} label={option} {...getTagProps({ index })} />
            )))
        }
        value={values[name]}
        onChange={onChange || ((e, value) => setFieldValue(name, value))}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        )}
      />
      <ErrorText errors={errors} name={name} touched={touched} />
    </Box>
  );
};

export const AppSlider = ({
  style,
  label,
  name,
  values,
  setFieldValue,
  errors,
  touched,
  tooltipTitle,
  ...props
}) => {
  return (
    <Box style={style}>
      <FieldLabel label={label} tooltipTitle={tooltipTitle} />
      <SliderStyled
        {...props}
        name={name}
        value={values[name]}
        onChange={(e, value) => setFieldValue(name, value)}
      />
      <ErrorText errors={errors} name={name} touched={touched} />
    </Box>
  );
};

export const FormButtons = ({ resetForm, dirty, isSubmitting }) => {
  const { t } = useTranslation();

  return (
    <ButtonsContainer>
      <ButtonStyled
        variant="outlined"
        startIcon={<Restore />}
        onClick={resetForm}
        disabled={!dirty || isSubmitting}
      >
        {t(`${translationJSONPrefix}.buttons.reset`)}
      </ButtonStyled>
      <LoadingButtonStyled
        variant="contained"
        type="submit"
        disabled={!dirty || isSubmitting}
        loading={isSubmitting}
      >
        {t(`${translationJSONPrefix}.buttons.save`)}
      </LoadingButtonStyled>
    </ButtonsContainer>
  );
};

const AppForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  formRenderFunction,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {formRenderFunction}
    </Formik>
  );
};

FieldLabel.propTypes = {
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

ErrorText.propTypes = {
  errors: PropTypes.object,
  name: PropTypes.string,
  touched: PropTypes.object,
};

ToogleContainer.propTypes = {
  width: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  tooltipTitle: PropTypes.string,
};

AppRadioGroup.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object,
  name: PropTypes.string,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  tooltipTitle: PropTypes.string,
  width: PropTypes.string,
  options: PropTypes.array,
};

AppTextField.propTypes = {
  style: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  tooltipTitle: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  isPasswordField: PropTypes.bool,
};

AppSelectField.propTypes = {
  style: PropTypes.object,
  tooltipTitle: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.object,
  label: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  menuData: PropTypes.array,
};

AppAutocompleteField.propTypes = {
  style: PropTypes.object,
  label: PropTypes.string,
  tooltipTitle: PropTypes.string,
  options: PropTypes.array,
  defaultValue: PropTypes.array,
  errors: PropTypes.object,
  touched: PropTypes.object,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  renderTags: PropTypes.func,
  onChange: PropTypes.func,
};

AppSlider.propTypes = {
  style: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  tooltipTitle: PropTypes.string,
};

FormButtons.propTypes = {
  resetForm: PropTypes.func,
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

AppForm.propTypes = {
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  onSubmit: PropTypes.func,
  formRenderFunction: PropTypes.func,
};

export default AppForm;

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState } from 'react';

import PageContainer from '../../../components/pageContainer';
import { SECTIONS } from '../../../constants/drawer';
import Header from '../../../components/form/header.component';
import { useTranslation } from 'react-i18next';
import {
  useLazyGetAllStorageConnectionsQuery,
  useUpdateDefaultStorageConnectionMutation,
  useDeleteStorageConnectionsMutation,
} from '../../../redux/services/speciphicAsk';
import UnauthorizedMessage from '../../../components/unauthorized';
import Loader from '../../../components/loader';
import DataGridTable from '../../../components/datagrid';
import { sortByDateField } from '../../../utils/functions';
import DisplayTime from '../../../components/timezone.component';
import { DataGridWrapper } from '../styled';
import { useNavigate } from 'react-router-dom';
import { APP_CONFIGURATION } from '../../../constants/path';
import {
  ADD_PAGE,
  STORAGE_CREDENTIAL_CONFIG,
} from '../../../constants/appConfigurationPaths';
import { useDispatch } from 'react-redux';
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import { Tooltip } from '@mui/material';

const translationJSONPrefix = 'appConfigurationSection.storageCredentialConfig';

const addPageRoute = `${APP_CONFIGURATION}${STORAGE_CREDENTIAL_CONFIG.endpoint}${ADD_PAGE.endpoint}`;

const columns = (t) => {
  const translationPrefix = `${translationJSONPrefix}.table.columnHeaders`;

  return [
    {
      field: 'name',
      headerName: t(`${translationPrefix}.name`),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => `${params?.row?.name}`,
    },
    {
      flex: 0.5,
      minWidth: 50,
      renderCell: (params) => (
        <>
          {params?.row?.default && (
            <Tooltip title="Default Model">
              <OfflinePinIcon color="success" />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: 'type',
      headerName: t(`${translationPrefix}.modelName`),
      flex: 0.5,
      minWidth: 200,
    },
    {
      field: 'createdAt',
      headerName: t(`${translationPrefix}.createdAt`),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => <DisplayTime time={params.row?.createdDate} />,
    },
    {
      field: 'updatedAt',
      headerName: t(`${translationPrefix}.updatedAt`),
      flex: 0.5,
      minWidth: 200,
      renderCell: (params) => (
        <DisplayTime time={params.row?.lastUpdatedDate} />
      ),
    },
  ];
};

const StorageCredentialsConfigPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedLMs, setSelectedLMs] = useState([]);
  const [isDrawerOpen, setDrawerOpen] = useState();

  const [
    getStorageConnections,
    {
      data: storageData = [],
      error: storageDataError,
      isError: isStorageDataError,
      isLoading: isStorageDataLoading,
      isSuccess: isStorageDataSuccess,
      isFetching: isStorageDataFetching,
    },
  ] = useLazyGetAllStorageConnectionsQuery();

  const [
    updateDefaultStorageConnections,
    {
      data: updateStorage = [],
      error: updateStorageDataError,
      isError: isupdateStorageDataError,
      isLoading: isupdateStorageDataLoading,
      isSuccess: isupdateStorageDataSuccess,
      isFetching: isupdateStorageDataFetching,
    },
  ] = useUpdateDefaultStorageConnectionMutation();

  const [
    deleteStorageConnections,
    {
      data: deletedStorage = [],
      error: deletedStorageError,
      isError: isdeletedStorageError,
      isLoading: isdeletedStorageLoading,
      isSuccess: isdeletedStorageSuccess,
      isFetching: isdeletedStorageFetching,
    },
  ] = useDeleteStorageConnectionsMutation();

  useEffect(() => {
    getStorageConnections({
      exclude: undefined,
      include: undefined,
      type: undefined,
    });
  }, []);

  const handleCellClick = (e) => {
    if (e?.field === 'name') navigate(`${addPageRoute}?id=${e?.row?.id}`);
  };

  const setDefaultStorage = () => {
    if (selectedLMs.length === 1) {
      const id = selectedLMs[0];
      updateDefaultStorageConnections({
        id: id,
        update: { default: true },
      });
    }
  };

  const deleteStorage = () => {
    if (selectedLMs.length > 0) {
      deleteStorageConnections({
        ids: selectedLMs,
      });
    }
  };

  if (storageDataError?.status === 403)
    return (
      <UnauthorizedMessage
        drawerOpen={isDrawerOpen}
        drawer={SECTIONS.ACCESS_CONTROL}
        description={t(`${translationJSONPrefix}.unauthorizedMessages.list`)}
      />
    );

  return (
    <PageContainer
      isDrawerOpen={(res) => setDrawerOpen(res)}
      drawer={SECTIONS.APP_CONFIGURATION}
    >
      <Header
        header={'Storage Credentials'}
        addButtonLabel={t('appConfigurationSection.components.header.add')}
        deleteButton={true}
        deleteButtonDisabled={
          selectedLMs.length === 0 || isupdateStorageDataLoading
        }
        addButtonOnClick={() => navigate(addPageRoute)}
        deleteButtonOnClick={deleteStorage}
        defaultButtonOnClick={setDefaultStorage}
        defaultButtonDisabled={
          selectedLMs?.length == 0 ||
          selectedLMs?.length > 1 ||
          isdeletedStorageLoading
        }
        defaultButton={true}
        isDefaultLoading={isupdateStorageDataLoading}
        isDeleteLoading={isdeletedStorageLoading}
      />
      {isStorageDataLoading ? (
        <Loader label={t(`${translationJSONPrefix}.loadingLabel`)} />
      ) : (
        <DataGridWrapper>
          <DataGridTable
            onCellClick={handleCellClick}
            row={sortByDateField([...storageData], 'lastUpdatedDate')}
            column={columns(t)}
            checkboxSelection
            autoHeight
            rowId={'id'}
            onSelectionModelChange={setSelectedLMs}
          />
        </DataGridWrapper>
      )}
    </PageContainer>
  );
};

export default StorageCredentialsConfigPage;

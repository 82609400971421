/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export const ingestFileEPFactory = (builder) =>
  builder.mutation({
    query: ({ collectionId, file, meta }) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('dataSourceUpload', true);
      if (meta) {
        formData.append('data', JSON.stringify({ meta }));
      }

      return {
        url: `/file-collections/${collectionId}`,
        method: 'POST',
        body: formData,
      };
    },
    invalidatesTags: ['ingestedFiles'],
  });

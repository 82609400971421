import React, { useState, useEffect } from 'react';
import { Typography, Link, Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  StyleShareTypography,
  StyledIconButton,
  FeedBackWrapper,
  AnswerComponentBottomWrapper,
  FooterButtonWrapper,
  FooterButtons,
} from './answer.styled';
import { LoadingButton } from '@mui/lab';
import { Download, IosShare } from '@mui/icons-material';

const AnswerFooter = ({
  title,
  description,
  meta,
  feedback,
  feedbackAPI,
  query,
  result,
  showDownloadButton = false,
  downloadButtonOnClick = () => {},
  isDownloading = false,
}) => {
  const { t } = useTranslation();

  const [feedbackResponse, setFeedbackResponse] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const { isAddFeedbackLoading, isAddFeedbackSuccess } = feedbackAPI;

  useEffect(() => {
    setFeedbackSubmitted(isAddFeedbackSuccess);
  }, [isAddFeedbackSuccess]);

  useEffect(() => {
    setFeedbackSubmitted(false);
  }, [title]);

  const subjectOfMail = t('answer.emailSubject', {
    query: query,
  });

  const bodyOfMail = t('answer.emailBody', {
    title: title,
    description: description,
    meta: meta,
    downloadUriPart: meta?.url,
  });

  const handleFeedbackSend = (bool) => {
    setFeedbackResponse(bool ? 'y' : 'n');
    feedback(result, bool);
  };

  return (
    <AnswerComponentBottomWrapper>
      <FeedBackWrapper>
        <Box>
          <Typography sx={{ fontSize: '18px', fontWeight: 300 }}>
            {feedbackSubmitted
              ? t('answer.afterFeedbackText')
              : t('answer.feedbackText')}
          </Typography>
        </Box>
        {!feedbackSubmitted && (
          <>
            <LoadingButton
              variant="contained"
              loading={feedbackResponse === 'y' && isAddFeedbackLoading}
              onClick={() => handleFeedbackSend(true)}
              sx={{ marginRight: '10px', marginLeft: '10px' }}
            >
              Yes
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              loading={feedbackResponse === 'n' && isAddFeedbackLoading}
              onClick={() => handleFeedbackSend(false)}
            >
              No
            </LoadingButton>
          </>
        )}
      </FeedBackWrapper>

      <FooterButtons>
        {showDownloadButton && (
          <FooterButtonWrapper>
            <StyledIconButton size="small" onClick={downloadButtonOnClick}>
              {isDownloading ? (
                <CircularProgress size={15} />
              ) : (
                <Download fontSize="15px" />
              )}
              <StyleShareTypography variant="subtitle1">
                {t('answer.downloadButton')}
              </StyleShareTypography>
            </StyledIconButton>
          </FooterButtonWrapper>
        )}

        <FooterButtonWrapper>
          <Link
            href={`mailto:?subject=${subjectOfMail}&body=${bodyOfMail}`}
            target="_blank"
            rel="noreferrer"
            underline="none"
          >
            <StyledIconButton size="small">
              <IosShare fontSize="15px" />
              <StyleShareTypography variant="subtitle1">
                {t('answer.shareButton')}
              </StyleShareTypography>
            </StyledIconButton>
          </Link>
        </FooterButtonWrapper>
      </FooterButtons>
    </AnswerComponentBottomWrapper>
  );
};

AnswerFooter.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  meta: PropTypes.array,
  feedback: PropTypes.func,
  feedbackAPI: PropTypes.object,
  query: PropTypes.string,
  result: PropTypes.object,
  showDownloadButton: PropTypes.bool,
  downloadButtonOnClick: PropTypes.func,
  isDownloading: PropTypes.bool,
};

export default AnswerFooter;

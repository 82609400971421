/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export default (builder) =>
  builder.mutation({
    query: ({
      name,
      description = '',
      indexName,
      tags,
      searchEngine,
      dataSource,
      isIngestionEnabled,
      isPersonal = false,
    }) => {
      return {
        url: `file-collections`,
        method: 'POST',
        body: {
          name: name,
          description: description,
          tags: tags,
          dataStoreIndex: indexName,
          languageModelId: searchEngine,
          storageId: dataSource,
          isIngestionEnabled,
          isPersonal,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      };
    },
    invalidatesTags: ['fileCollections'],
  });

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import styled from '@emotion/styled';
import { Box, FormControl, Typography } from '@mui/material';

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  width: '270px',

  [theme.breakpoints.down('md')]: {
    width: '140px',
  },

  [theme.breakpoints.down('sm')]: {
    width: '120px',
  },
}));

export const StyledBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const DisplayText = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const DropdownTextWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const AddBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import PropTypes from 'prop-types';

import {
  AWS_S3_TYPE,
  AZURE_BLOB_TYPE,
  FTP_TYPE,
  SHAREPOINT_TYPE,
  SERVICENOW_TYPE,
  GCP_GCS_TYPE,
} from '../../../constants/dataSources';
import AWSForm from './awsForm';
import AzureBlobForm from './azureBlobForm';
import SharepointForm from './sharepointForm';
import ServiceNowForm from './serviceNowForm';
import GcpGcsForm from './gcpGcsForm';
import FTPForm from './ftpForm';

const DataSourceForm = ({ sourceType, storageConfigId }) => {
  switch (sourceType) {
    case AZURE_BLOB_TYPE:
      return (
        <AzureBlobForm
          storageContainer={AZURE_BLOB_TYPE}
          storageConfigId={storageConfigId}
        />
      );
    case SHAREPOINT_TYPE:
      return (
        <SharepointForm
          storageContainer={SHAREPOINT_TYPE}
          storageConfigId={storageConfigId}
        />
      );
    case SERVICENOW_TYPE:
      return (
        <ServiceNowForm
          storageContainer={SERVICENOW_TYPE}
          storageConfigId={storageConfigId}
        />
      );
    case GCP_GCS_TYPE:
      return (
        <GcpGcsForm
          storageContainer={GCP_GCS_TYPE}
          storageConfigId={storageConfigId}
        />
      );
    case AWS_S3_TYPE:
      return (
        <AWSForm
          storageContainer={AWS_S3_TYPE}
          storageConfigId={storageConfigId}
        />
      );
    case FTP_TYPE:
      return <FTPForm storageConfigId={storageConfigId} />;
    default:
      return <AzureBlobForm storageConfigId={storageConfigId} />;
  }
};

DataSourceForm.propTypes = {
  sourceType: PropTypes.string,
  storageConfigId: PropTypes.string,
};

export default DataSourceForm;

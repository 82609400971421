export const GENERAL = {
  title: 'general',
  endpoint: '/',
};
export const MARKET_INTELLIGENCE = {
  title: 'marketIntelligence',
  endpoint: '/market-intelligence',
};
export const EXTERNAL_CHUNKING_CONFIG = {
  title: 'externalChunking',
  endpoint: '/external-preprocessors',
};
export const EXTERNAL_EMBEDDING_CONFIG = {
  title: 'externalEmbedding',
  endpoint: '/custom-embeddings',
};
export const LANGUAGE_MODELS_CONFIG = {
  title: 'languageModelsConfig',
  endpoint: '/language-models-config',
};
export const ADD_PAGE = {
  endpoint: '/add',
};

export const STORAGE_CREDENTIAL_CONFIG = {
  title: 'storageCredentialConfig',
  endpoint: '/storage-credential-config',
};

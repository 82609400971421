/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import React, { useEffect, useState, useContext, useCallback } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Grid,
  Box,
  LinearProgress,
  Typography,
  Container,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import {
  Backup,
  Cancel,
  CheckCircle,
  CloudDone,
  FileUpload,
  Launch,
  Warning,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import { useDropzone } from 'react-dropzone';

import SearchSuggestion from './searchSuggestion';
import {
  ShadowBox,
  AnswerComponentWrapper,
  LoadingTypography,
  ResultBox,
  FoundDataAlert,
  ResultTitle,
  HoveringLink,
  MetaDataGridBox,
  InfographicSVG,
  FilterBox,
  UploadFileBox,
  FilterTableBox,
} from './search.page.styled';
import { SearchbarBase } from '../../components/searchbar';
import { Answer, AnswerWithPdf } from '../../components/answer';
import PageContainer from '../../components/pageContainer';
import {
  useGetResultsMutation,
  useAddFeedbackMutation,
  useLazyGetFaqQuery,
  useLazyGetMetaDataPropertiesQuery,
  useLazyGetIndividualFileCollectionQuery,
  useAddMetaDataFiltersMutation,
  useAddConversationMutation,
  useLazyGetIngestedFilesQuery,
  useAddFilesToDataSourceMutation,
  useIngestFileMutation,
  useGetIngestFilePermissionMutation,
} from '../../redux/services/speciphicAsk';
import { pushToast } from '../../redux/reducers/toasts.slice';
import isMetadataFunctionalityEnabledInApp from '../../constants/metadata';
import * as PATHS from '../../constants/path';
import { EXTRACTIVE_SEARCH_ENGINE } from '../../constants/searchEngines';
import { FileCollectionContext } from '../../hooks/useContext';
import BooleanField from '../../components/booleanField';
import MetaDataGrid from '../../components/metadataGrid';
import { SiteConfigContext } from '../../hooks/useSiteConfigContext';
import isConversationHistoryEnabled from '../../constants/conversationHistory';
import colors from '../../themes/colors';
import DataGridTable from '../../components/datagrid/datagrid.component';
import useDebounce from '../../hooks/useDebounce';
import { DEBOUNCE_DELAY } from '../../constants/debounce';
import { filterByIds, formatBytes } from '../../utils/functions';

const params = (languageCode, searchQuery, collectionId) => {
  return { q: searchQuery, lang: languageCode, cid: collectionId };
};

const mockColumns = [
  {
    field: 'fileName',
    headerName: 'File Name',
    width: 250,
    renderHeader: () => <div>File Name</div>,
  },
  {
    field: 'status',
    headerName: 'Status',
    minwidth: 100,
    flex: 1,
    renderCell: (params) => {
      const status = params.value;
      let icon, color, tooltip;

      switch (status) {
        case 'completed':
          icon = <CloudDone />;
          color = colors.green.G400;
          tooltip = 'Ingested';
          break;

        case 'uploading':
          icon = <CircularProgress size={20} />;
          color = colors.neutralLight.N200;
          tooltip = 'Uploading';
          break;

        case 'rejected':
          icon = <Cancel />;
          color = colors.red.R400;
          tooltip = 'Something went wrong';
          break;

        default:
          icon = null;
          break;
      }

      return (
        <Tooltip title={tooltip || ''}>
          <span style={{ color, display: 'flex', alignItems: 'center' }}>
            {icon}
          </span>
        </Tooltip>
      );
    },
  },
  {
    field: 'size',
    headerName: 'Size',
    width: 100,
    renderCell: (params) => {
      const sizeInBytes = params.row.size;
      const formattedSize = formatBytes(sizeInBytes);
      return <div>{formattedSize}</div>;
    },
  },
];

const mockRows = [
  { id: 'img2', fileName: 'photo.jpg', status: 'uploading' },
  { id: 'aud8', fileName: 'music.wav', status: 'uploading' },
  { id: 'doc5', fileName: 'invoice.docx', status: 'uploading' },
  { id: 'vid3', fileName: 'presentation.mp4', status: 'rejected' },
  { id: 'img6', fileName: 'diagram.png', status: 'rejected' },
  { id: 'doc9', fileName: 'notes.txt', status: 'rejected' },
  { id: 'aud4', fileName: 'podcast.mp3', status: 'completed' },
  { id: 'vid7', fileName: 'tutorial.mov', status: 'completed' },
  { id: 'doc1', fileName: 'report.pdf', status: 'completed' },
  { id: 'img10', fileName: 'banner.svg', status: 'completed' },
];

const SearchPage = () => {
  const { t } = useTranslation();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [input, setInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isValidResult, setIsValidResult] = useState(false);
  const [inputTextForAlert, setInputTextForAlert] = useState('');
  const [faqList, setFaqList] = useState([]);
  const [languageCode, setLanguageCode] = useState('');
  const [showAnswerContainer, setShowAnswerContainer] = useState(true);
  const [suggestiveSearch, setSuggestiveSearch] = useState(false);
  const [isQueryMetaData, setIsQueryMetaData] = useState(true);
  const [isGetMetaDataGrid, setIsMetaDataGrid] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [gridSize, setGridSize] = useState(3);
  const {
    getCollectionId,
    isFileCollectionChanged,
    getSearchEngine,
    updateCollectionId,
    getSelectedFileCollection,
  } = useContext(FileCollectionContext);
  const { getSiteConfig } = useContext(SiteConfigContext);
  const [clearScreen, setClearScreen] = useState(false);
  const [infographics, setInfographics] = useState({
    type: 'text',
    file: '',
    text: '',
  });
  const [currentTime, setCurrentTime] = useState('');

  const [filterClick, setFilterClick] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState(
    mockRows.map((row) => row.id),
  );
  const [filterTableRowsData, setFilterTableRowsData] = useState([]);
  const [filterTableSearchQuery, setFilterTableSearchQuery] = useState('');
  const [ingestFilePermission, setIngestFilePermission] = useState('deny');
  const [generatingMessage, setGeneratingMessage] = useState(
    t('trainedquestionAnswerPage.loadingMessage'),
  );

  const [
    getIngestedFiles,
    {
      data: ingestedFilesObj,
      error: ingestedFilesError,
      isFetching: isGetIngestedFilesFetching,
    },
  ] = useLazyGetIngestedFilesQuery();

  const [
    getPermissionForIngestedFiles,
    {
      data: ingestedFilesPermission = [],
      error: ingestedFilesPermissionError,
      isFetching: isGetIngestedFilesPermissionFetching,
      isSuccess: isGetIngestedFilesPermissionSuccess,
    },
  ] = useGetIngestFilePermissionMutation();

  useEffect(() => {
    let fCId = getCollectionId();
    if (fCId) {
      getPermissionForIngestedFiles({
        actions: ['FileCollection:IngestFiles', 'FileCollection:View'],
        resourceIds: [fCId],
      });
    }
  }, []);

  useEffect(() => {
    let fCId = getCollectionId();
    if (fCId) {
      getPermissionForIngestedFiles({
        actions: ['FileCollection:IngestFiles', 'FileCollection:View'],
        resourceIds: [fCId],
      });
    }
  }, [getCollectionId()]);

  useEffect(() => {
    if (ingestedFilesPermission && isGetIngestedFilesPermissionSuccess) {
      let resourceId = getCollectionId();
      let permission = 'FileCollection:IngestFiles';
      setIngestFilePermission(
        ingestedFilesPermission?.[resourceId]?.[permission],
      );
    }
  }, [ingestedFilesPermission, isGetIngestedFilesPermissionSuccess]);

  const [
    ingestFile,
    {
      data: ingestFileResponse,
      error: ingestFileError,
      isLoading: isIngesting,
    },
  ] = useIngestFileMutation();

  useDebounce(
    async () => {
      const collectionId = getCollectionId();

      if (collectionId) {
        const data = await getIngestedFiles({
          collectionId,
          pageNo: 1,
          pageSize: 500,
          query: filterTableSearchQuery,
        }).unwrap();

        const ingestedFiles = [];

        data?.ingestedFiles?.forEach((ingf) => {
          ingestedFiles.push({
            id: ingf.id,
            fileName: ingf.fileName,
            status: ingf.status === 'SUCCESS' ? 'completed' : 'rejected',
            size: ingf.size,
          });
        });

        setFilterTableRowsData(ingestedFiles);
        // setRowSelectionModel(ingestedFiles.map((ingf) => ingf.id));
        // Filter rows where status is not 'rejected' for the selection model
        const validSelection = ingestedFiles
          .filter((ingf) => ingf.status !== 'rejected')
          .map((ingf) => ingf.id);

        setRowSelectionModel(validSelection);
      }
    },
    [filterTableSearchQuery, getCollectionId()],
    DEBOUNCE_DELAY,
  );

  const [
    addFilesToDataSource,
    {
      data: addFiles,
      isSuccess: isAddFilesSuccess,
      isLoading: isAddFilesLoading,
      isError: isAddFilesError,
    },
  ] = useAddFilesToDataSourceMutation();

  const getUniqueAcceptedFiles = (acceptedFiles, stateData) => {
    const existingFileNames = new Set(stateData.map((row) => row.fileName));

    const uniqueFiles = [];
    const seenFileNames = new Set();

    acceptedFiles.forEach((file) => {
      if (!existingFileNames.has(file.name) && !seenFileNames.has(file.name)) {
        uniqueFiles.push(file);
        seenFileNames.add(file.name); // Mark this file name as seen
      }
    });

    return uniqueFiles;
  };

  const onDrop = useCallback(
    async (accepFiles) => {
      const acceptedFiles = getUniqueAcceptedFiles(
        accepFiles,
        filterTableRowsData,
      );

      const newFiles = [];

      acceptedFiles.forEach((af) =>
        newFiles.push({
          id: af?.name,
          fileName: af?.name,
          status: 'uploading',
        }),
      );

      const tempRows = [...newFiles, ...filterTableRowsData];

      setFilterTableRowsData(tempRows);
      setRowSelectionModel([
        ...rowSelectionModel,
        ...newFiles.map((row) => row.id),
      ]);

      const uploadPromises = acceptedFiles.map((accepFile) =>
        ingestFile({
          collectionId: getCollectionId(),
          file: accepFile,
        })
          .unwrap()
          .then((ingestFileRes) => ({ ingestFileRes, accepFile }))
          .catch((error) => ({ error, accepFile })),
      );

      const results = await Promise.allSettled(uploadPromises);

      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          const { ingestFileRes, accepFile } = result.value;
          const index = tempRows
            .map((tr) => tr.fileName)
            .indexOf(accepFile.name);
          if (index !== -1) {
            tempRows[index].status =
              ingestFileRes?.status === 'SUCCESS' ? 'completed' : 'rejected';
          }
        } else {
          const { accepFile } = result.reason;
          const index = tempRows
            .map((tr) => tr.fileName)
            .indexOf(accepFile?.name);
          if (index !== -1) {
            tempRows[index].status = 'rejected';
          }
        }
      });

      setFilterTableRowsData([...tempRows]);
    },
    [filterTableRowsData],
  );

  const isRowSelectable = (params) => {
    return params?.row?.status !== 'rejected';
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const getFilteredFilesToAsk = () => {
    const successFilterTableRows = filterTableRowsData.filter(
      (file) => file.status === 'completed',
    );
    return filterByIds(rowSelectionModel, successFilterTableRows).map(
      (f) => f.fileName,
    );
  };

  const displayMessageWhileSearching = () => {
    const fileNames = getFilteredFilesToAsk();

    if (fileNames.length === 0) return '';

    return `${fileNames.length} files`;
  };

  useEffect(() => {
    const siteConfig = getSiteConfig();

    setInfographics({
      type: siteConfig?.infographicsType || 'svg',
      file: siteConfig?.infographicsFile || '',
      text: siteConfig?.infographicsText || '',
    });
  }, [getSiteConfig()]);

  const [
    getFaq,
    {
      data: faqs = [],
      isError: isGetFaqError,
      isLoading: isGetFaqLoading,
      isSuccess: isGetFaqSuccess,
      isFetching: isGetFaqFetching,
    },
  ] = useLazyGetFaqQuery();

  useEffect(() => {
    setFaqList(faqs);
  }, [isGetFaqFetching]);

  const [
    addFeedback,
    {
      data: feedbackData = '',
      isError: isAddFeedbackError,
      isLoading: isAddFeedbackLoading,
      isSuccess: isAddFeedbackSuccess,
    },
  ] = useAddFeedbackMutation();

  const [
    findAnswers,
    {
      data: result,
      isSuccess: isGetResultsSuccess,
      isLoading: isGetResultsLoading,
      isError: isGetResultsError,
    },
  ] = useGetResultsMutation();

  const [
    addConversation,
    {
      data: conversation = [],
      isSuccess: isGetConversationSuccess,
      isLoading: isGetConversationLoading,
      isError: isGetConversationError,
    },
  ] = useAddConversationMutation();

  const [
    findMetaData,
    {
      data: response = [],
      isSuccess: isGetMetaDataSuccess,
      isLoading: isGetMetaDataLoading,
      isError: isGetMetaDataError,
    },
  ] = useAddMetaDataFiltersMutation();

  const [
    metadataProperties,
    {
      data: definedMetaProperties = {},
      isError: isGetMetaPropertiesError,
      isLoading: isGetMetaPropertiesLoading,
      isSuccess: isGetMetaPropertiesSuccess,
    },
  ] = useLazyGetMetaDataPropertiesQuery();

  const [
    getFileCollection,
    {
      data: individualFileCollection,
      isError: isGetIndividualFileCollectionError,
      isLoading: isGetIndividualFileCollectionLoading,
      isSuccess: isGetIndividualFileCollectionSuccess,
    },
  ] = useLazyGetIndividualFileCollectionQuery();

  const freshInput = () => {
    const collectionId = getCollectionId();

    if (!collectionId) return;

    getFaq({
      collectionId,
    });
    getFileCollection({
      collectionId,
    });
    setInput('');
    setErrorMessage('');
    setIsValidResult(false);
    setInputTextForAlert('');
    setShowAnswerContainer(true);
    setSuggestiveSearch(false);
    setIsQueryMetaData(true);
    setIsMetaDataGrid(true);
    setPageNo(1);
    setGridSize(3);
  };

  useEffect(() => {
    if (result) {
      setGeneratingMessage(t('trainedquestionAnswerPage.loadingMessage'));

      if (result?.hasError) {
        setIsValidResult(false);
        setErrorMessage(result.message);
      } else {
        const time = moment();
        setIsValidResult(true);
        setCurrentTime(time.format('YYYY-MM-DDTHH:mm:ssZ'));
      }
      setClearScreen(false);
    }
  }, [isGetResultsSuccess, result]);

  useEffect(() => {
    if (isAddFeedbackSuccess) {
      dispatch(
        pushToast({
          message: t('speciphicAskPage.feedbackSuccess'),
          severity: 'success',
        }),
      );
    }
  }, [isAddFeedbackSuccess]);

  useEffect(() => {
    if (isGetResultsError) {
      setIsValidResult(false);
      setErrorMessage(t('speciphicAskPage.errorResult'));

      dispatch(
        pushToast({
          message: t('speciphicAskPage.errorResult'),
          severity: 'error',
        }),
      );
    }
  }, [isGetResultsError]);

  const handleLanguageChange = (value) => {
    setLanguageCode(value.code);
  };

  const handleClickFetchAnswer = (collectionId, query, languageCode) => {
    if (
      isMetadataFunctionalityEnabledInApp &&
      individualFileCollection?.isMetadataEnabled
    ) {
      metadataProperties({
        collectionId,
      });
    }

    if (filterClick)
      setGeneratingMessage(
        `Generating answer from ${displayMessageWhileSearching()} ...`,
      );

    findAnswers({
      collectionId,
      query,
      acceptLanguage: languageCode,
      includeMetadata:
        isMetadataFunctionalityEnabledInApp &&
        individualFileCollection?.isMetadataEnabled &&
        isQueryMetaData,
      filters:
        filterClick && getFilteredFilesToAsk().length > 0
          ? {
              fileName: getFilteredFilesToAsk(),
            }
          : {},
    });

    setFilterClick(false);
    setInputTextForAlert(query);
    setClearScreen(true);
    setShowAnswerContainer(false);
    navigate({
      pathname: `${PATHS.HOMEPAGE}`,
      search: `${createSearchParams(
        params(languageCode, query, collectionId),
      )}`,
    });
    if (
      isMetadataFunctionalityEnabledInApp &&
      isGetMetaDataGrid &&
      individualFileCollection?.isMetadataEnabled
    ) {
      findMetaData({
        collectionId,
        query,
        size: gridSize,
        pageNo: pageNo,
      });
    }
  };

  useEffect(() => {
    if (suggestiveSearch) {
      handleClickFetchAnswer(getCollectionId(), input, languageCode);
      setSuggestiveSearch(false);
    }
  }, [suggestiveSearch, getCollectionId()]);

  // this useEffect for auto fetch answer for url
  useEffect(() => {
    if (!location.search) {
      setShowAnswerContainer(true);
    }

    if (search.get('cid')) {
      const query = search.get('q');
      const languageCode = search.get('lang');
      const collectionId = search.get('cid');

      setInput(query);
      setLanguageCode(languageCode);

      updateCollectionId(collectionId);

      handleClickFetchAnswer(collectionId, query, languageCode);
    } else freshInput();
  }, []);

  useEffect(() => {
    freshInput();

    if (search.get('q')) {
      navigate({
        pathname: `${PATHS.HOMEPAGE}`,
      });
    }
  }, [isFileCollectionChanged]);

  const handleClickFeedback = (res, bool) => {
    {
      isGetResultsSuccess &&
        addFeedback({
          chatId: result.id,
          isHelpful: bool,
        });
    }
  };

  const handleMetaDataButton = () => {
    const collectionId = getCollectionId();

    navigate(`${PATHS.SEARCH_METADATA_PAGE}/${collectionId}`, {
      state: {
        collectionId: collectionId,
      },
    });
  };

  const handleMetaDataGrid = (value) => {
    setIsMetaDataGrid(value);
  };

  const handleNavigateConversationPath = () => {
    addConversation({
      collectionId: getCollectionId(),
      query: null,
      acceptLanguage: 'en-US',
      includeMetadata: true,
      conversationId: null,
      chatHistoryId: result?.id,
    });
  };

  useEffect(() => {
    const collectionId = getCollectionId();

    if (isGetConversationSuccess) {
      navigate(
        `${PATHS.FILE_COLLECTIONS}/${collectionId}/${PATHS.CONVERSATION}/${conversation[0].conversation_id}`,
        {
          state: {
            fileCollectionId: collectionId,
            conversationId: conversation[0].conversation_id,
            isConversationHistorySent: true,
            time: currentTime,
          },
        },
      );
    }
  }, [isGetConversationSuccess]);

  const getIsDatabaseSource = () => {
    return getSelectedFileCollection()?.isStorageSource;
  };

  return (
    <>
      <PageContainer>
        <>
          {infographics?.type === 'svg' ? (
            <InfographicSVG src={infographics?.file} alt="Infographics" />
          ) : (
            <>
              <Typography
                variant="h1"
                sx={{
                  fontSize: '56px',
                  fontWeight: 200,
                  color: '#131E29',
                  marginTop: '100px',
                }}
              >
                {infographics?.text || ''}
              </Typography>
            </>
          )}
        </>

        <Container sx={{ width: '100%', paddingX: { xs: 1, sm: 8 } }}>
          <>
            <ShadowBox>
              <Grid container spacing={2}>
                <SearchbarBase
                  onSearchClick={() =>
                    handleClickFetchAnswer(
                      getCollectionId(),
                      input,
                      languageCode,
                    )
                  }
                  onFilterClick={() => setFilterClick(!filterClick)}
                  value={input}
                  setQueryLanguage={handleLanguageChange}
                  onChange={setInput}
                  placeholder={t(
                    'trainedquestionAnswerPage.searchbarBase.placeholder',
                  )}
                  showFilter={!getIsDatabaseSource()}
                />

                {filterClick && getCollectionId() != undefined && (
                  <FilterBox>
                    {ingestFilePermission == 'allow' && (
                      <UploadFileBox {...getRootProps()}>
                        <input {...getInputProps()} />
                        <FileUpload style={{ fontSize: 50 }} />
                        <div>
                          {isDragActive
                            ? 'Drop the files here ...'
                            : 'Upload files'}
                        </div>
                      </UploadFileBox>
                    )}

                    <FilterTableBox permission={ingestFilePermission}>
                      <DataGridTable
                        rowId="id"
                        row={filterTableRowsData}
                        column={mockColumns}
                        onSearch={(e) => setFilterTableSearchQuery(e)}
                        serverSideSearch={true}
                        isSearching={isGetIngestedFilesFetching}
                        total={Math.max(
                          ingestedFilesObj?.total,
                          filterTableRowsData.length,
                        )?.toString()}
                        checkboxSelection
                        hideFooter
                        showSelectedCount
                        selectionModel={rowSelectionModel}
                        onSelectionModelChange={setRowSelectionModel}
                        isRowSelectable={isRowSelectable}
                      />
                    </FilterTableBox>
                  </FilterBox>
                )}
                {isGetIndividualFileCollectionSuccess &&
                  !individualFileCollection?.isStoreCreated &&
                  ingestedFilesObj?.total == 0 && (
                    <Alert
                      severity="error"
                      sx={{
                        marginTop: '8px',
                      }}
                    >
                      {t('trainedquestionAnswerPage.alertMessages.noFiles')}
                    </Alert>
                  )}

                {!filterClick &&
                  isMetadataFunctionalityEnabledInApp &&
                  individualFileCollection?.isMetadataEnabled &&
                  getCollectionId() && (
                    <Grid container item display="flex" xs={12}>
                      <Grid
                        container
                        item
                        xs={8}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        sx={{ paddingLeft: 4 }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          display="flex"
                          justifyContent="flex-start"
                          sx={{ py: 2 }}
                        >
                          <BooleanField
                            value={isQueryMetaData}
                            label={t(
                              'speciphicAskPage.metadataFields.includeMetadataLabel',
                            )}
                            onChange={(value) => setIsQueryMetaData(value)}
                            isLabelAndFieldOnHorizontalLine={true}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={5}
                          display="flex"
                          justifyContent="space-between"
                          sx={{ py: 2, px: 2 }}
                        >
                          <BooleanField
                            value={isGetMetaDataGrid}
                            label={t(
                              'speciphicAskPage.metadataFields.showMetadataMatchesLabel',
                            )}
                            onChange={(value) => handleMetaDataGrid(value)}
                            isLabelAndFieldOnHorizontalLine={true}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={4}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <HoveringLink
                          aria-label="search"
                          onClick={handleMetaDataButton}
                        >
                          {t(
                            'speciphicAskPage.metadataFields.goToMetadataSearch',
                          )}
                          <Launch sx={{ fontSize: 16, marginLeft: 0.5 }} />
                        </HoveringLink>
                      </Grid>
                    </Grid>
                  )}
              </Grid>
            </ShadowBox>

            {!filterClick &&
              showAnswerContainer &&
              getCollectionId() != undefined && (
                <SearchSuggestion
                  isLoading={isGetFaqFetching}
                  faqList={faqList}
                  setInput={setInput}
                  suggestiveTrue={setSuggestiveSearch}
                />
              )}
          </>

          {!showAnswerContainer ? (
            <ResultBox>
              {isGetResultsLoading ? (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <LinearProgress sx={{ width: '40%' }} />
                  <LoadingTypography
                    variant="h5"
                    paddingTop={1}
                    color="primary"
                  >
                    {generatingMessage}
                  </LoadingTypography>
                </Box>
              ) : (
                <>
                  {result?.hasError ? (
                    <Alert
                      severity="error"
                      sx={{
                        borderRadius: 0,
                      }}
                    >
                      {errorMessage}
                    </Alert>
                  ) : (
                    <>
                      {isValidResult && (
                        <>
                          <FoundDataAlert
                            severity="success"
                            sx={{
                              '.MuiSvgIcon-root': { color: '#FFFFFF' },
                              borderRadius: 0,
                            }}
                          >
                            {t(
                              'trainedquestionAnswerPage.alertMessages.foundData',
                              {
                                searchText: inputTextForAlert,
                              },
                            )}
                          </FoundDataAlert>
                        </>
                      )}
                      {isValidResult && (
                        <AnswerComponentWrapper>
                          <ResultTitle>
                            {t('trainedquestionAnswerPage.resultHeader')}

                            {isConversationHistoryEnabled &&
                              !getIsDatabaseSource() && (
                                <LoadingButton
                                  variant="contained"
                                  startIcon={<ChatIcon />}
                                  size="small"
                                  onClick={handleNavigateConversationPath}
                                  loading={isGetConversationLoading}
                                >
                                  {t('speciphicAskPage.followUpQuestion')}
                                </LoadingButton>
                              )}
                          </ResultTitle>

                          {getSearchEngine() === EXTRACTIVE_SEARCH_ENGINE ? (
                            <Answer
                              title={result?.answer || ''}
                              description={result?.context || ''}
                              meta={result?.originalFileName || ''}
                              feedback={handleClickFeedback}
                              feedbackAPI={{
                                feedbackData,
                                isAddFeedbackError,
                                isAddFeedbackLoading,
                                isAddFeedbackSuccess,
                              }}
                              result={result}
                              query={input}
                            />
                          ) : (
                            <AnswerWithPdf
                              result={result}
                              title={result?.answer || ''}
                              description={result?.context || ''}
                              metas={result?.meta || []}
                              pdfPreview={true}
                              feedback={handleClickFeedback}
                              feedbackAPI={{
                                feedbackData,
                                isAddFeedbackError,
                                isAddFeedbackLoading,
                                isAddFeedbackSuccess,
                              }}
                              query={input}
                              collectionId={getCollectionId()}
                              isDefaultFeedbackRequired={true}
                            />
                          )}
                        </AnswerComponentWrapper>
                      )}
                    </>
                  )}
                </>
              )}
            </ResultBox>
          ) : null}
          {!showAnswerContainer && !isGetResultsLoading && (
            <ResultBox>
              <>
                {isMetadataFunctionalityEnabledInApp &&
                  isGetMetaDataGrid &&
                  isGetMetaDataSuccess &&
                  result &&
                  response?.results?.length > 0 &&
                  !clearScreen && (
                    <MetaDataGridBox>
                      <Grid>
                        <Box sx={{ paddingTop: 2 }}>
                          <Typography
                            variant="h4"
                            sx={{ fontWeight: 'bold', fontSize: 20 }}
                          >
                            {t(
                              'speciphicAskPage.metadataFields.metadataGrid.filesRelatedToSearch',
                            )}
                          </Typography>
                        </Box>

                        <MetaDataGrid
                          files={response?.results || []}
                          definedMetaProperties={definedMetaProperties}
                          pageSize={gridSize}
                          onPageSizeChange={(newPageSize) =>
                            setGridSize(newPageSize)
                          }
                          pageNo={pageNo}
                          onPageNoChange={(newPageNo) => setPageNo(newPageNo)}
                          totalPages={`${response?.total_size}`}
                          isMainSearchPage={true}
                          noRowsMessage={t(
                            'speciphicAskPage.metadataFields.metadataGrid.noRowsMessage',
                          )}
                        />
                      </Grid>
                    </MetaDataGridBox>
                  )}
              </>
            </ResultBox>
          )}
        </Container>
      </PageContainer>
    </>
  );
};

export default SearchPage;

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Box, styled, CircularProgress } from '@mui/material';

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const InputsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '20px 0',
  gap: 20,
}));

export const Loader = styled(CircularProgress)(({ theme }) => ({
  height: '20px !important',
  width: '20px !important',
  marginRight: '10px',
}));

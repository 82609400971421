/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import {
  SpaceDashboard,
  QueryStats,
  PeopleAlt,
  Folder,
  Dashboard,
  Description,
  Settings,
  Code,
  Sort,
  Tune,
  QuestionAnswer,
  BarChart,
  ManageSearch,
  Policy,
  Group,
  Shield,
  Security,
  Person,
  Storage,
  Http,
  Memory,
  Key,
} from '@mui/icons-material';

import {
  ANALYTICS as ANALYTICS_PATH_PREFIX,
  FILE_COLLECTIONS as FILES_COLLECTION_PATH_PREFIX,
  ACCESS_CONTROL as ACCESS_CONTROL_PATH_PREFIX,
  SETTINGS,
  APP_CONFIGURATION as APP_CONFIG_PREFIX,
} from '../../constants/path';
import * as ANALYTICS_PATH from '../../constants/analyticsPaths';
import * as FILES_COLLECTION_PATH from '../../constants/filesCollectionSettingsPaths';
import * as APP_CONFIG_PATH from '../../constants/appConfigurationPaths';
import * as ACCESS_CONTROL_PATH from '../../constants/accessControlPaths';
import isAccessControlEnabled from '../../constants/accessControl';
import isMarketIntelligenceEnabled from '../../constants/marketIntelligence';

export const ANALYTICS = (t) => {
  const translationPrefix = `analyticsSection.drawerMenuTitles`;

  return [
    {
      title: t(`${translationPrefix}.${ANALYTICS_PATH.DASHBOARD.title}`),
      icon: <SpaceDashboard />,
      end_point: ANALYTICS_PATH_PREFIX + ANALYTICS_PATH.DASHBOARD.endpoint,
    },
    {
      title: t(
        `${translationPrefix}.${ANALYTICS_PATH.SEARCH_PERFORMANCE.title}`,
      ),
      icon: <QueryStats />,
      end_point:
        ANALYTICS_PATH_PREFIX + ANALYTICS_PATH.SEARCH_PERFORMANCE.endpoint,
    },
    {
      title: t(`${translationPrefix}.${ANALYTICS_PATH.FILE_COLLECTIONS.title}`),
      icon: <Folder />,
      end_point:
        ANALYTICS_PATH_PREFIX + ANALYTICS_PATH.FILE_COLLECTIONS.endpoint,
    },
    {
      title: t(`${translationPrefix}.${ANALYTICS_PATH.USERS.title}`),
      icon: <PeopleAlt />,
      end_point: ANALYTICS_PATH_PREFIX + ANALYTICS_PATH.USERS.endpoint,
    },
  ];
};

const FILES_COLLECTION_SETTINGS_ENDPOINT = (collectionId, page) =>
  `${FILES_COLLECTION_PATH_PREFIX}/${collectionId}${SETTINGS}${FILES_COLLECTION_PATH[page].endpoint}`;

export const FILES_COLLECTION_SETTINGS = (t, collectionId) => {
  const translationPrefix = (page) =>
    `filesCollectionSettingsPage.${page}.drawerMenuTitle`;

  const menuItems = [
    {
      title: t(`${translationPrefix(FILES_COLLECTION_PATH.GENERAL.title)}`),
      icon: <Dashboard />,
      end_point: FILES_COLLECTION_SETTINGS_ENDPOINT(collectionId, 'GENERAL'),
    },
    // {
    {
      title: t(
        `${translationPrefix(
          FILES_COLLECTION_PATH.SEARCH_RESULT_CONFIG.title,
        )}`,
      ),
      icon: <ManageSearch />,
      end_point: FILES_COLLECTION_SETTINGS_ENDPOINT(
        collectionId,
        'SEARCH_RESULT_CONFIG',
      ),
    },
    {
      title: t(`${translationPrefix(FILES_COLLECTION_PATH.DATA_CONFIG.title)}`),
      icon: <Storage />,
      end_point: FILES_COLLECTION_SETTINGS_ENDPOINT(
        collectionId,
        'DATA_CONFIG',
      ),
    },
    {
      title: t(
        `${translationPrefix(FILES_COLLECTION_PATH.METADATA_CONFIG.title)}`,
      ),
      icon: <Description />,
      end_point: FILES_COLLECTION_SETTINGS_ENDPOINT(
        collectionId,
        'METADATA_CONFIG',
      ),
    },
    {
      title: t(
        `${translationPrefix(FILES_COLLECTION_PATH.PREPROCESSOR_CONFIG.title)}`,
      ),
      icon: <Settings />,
      end_point: FILES_COLLECTION_SETTINGS_ENDPOINT(
        collectionId,
        'PREPROCESSOR_CONFIG',
      ),
    },
    {
      title: t(
        `${translationPrefix(FILES_COLLECTION_PATH.EMBEDDING_CONFIG.title)}`,
      ),
      icon: <Code />,
      end_point: FILES_COLLECTION_SETTINGS_ENDPOINT(
        collectionId,
        'EMBEDDING_CONFIG',
      ),
    },
    {
      title: t(
        `${translationPrefix(FILES_COLLECTION_PATH.RANKER_CONFIG.title)}`,
      ),
      icon: <Sort />,
      end_point: FILES_COLLECTION_SETTINGS_ENDPOINT(
        collectionId,
        'RANKER_CONFIG',
      ),
    },
    {
      title: t(`${translationPrefix(FILES_COLLECTION_PATH.LLM_CONFIG.title)}`),
      icon: <Tune />,
      end_point: FILES_COLLECTION_SETTINGS_ENDPOINT(collectionId, 'LLM_CONFIG'),
    },
    {
      title: t(
        `${translationPrefix(FILES_COLLECTION_PATH.PROMPT_CONFIG.title)}`,
      ),
      icon: <QuestionAnswer />,
      end_point: FILES_COLLECTION_SETTINGS_ENDPOINT(
        collectionId,
        'PROMPT_CONFIG',
      ),
    },
  ];

  const iamMenu = {
    title: t(`${translationPrefix(FILES_COLLECTION_PATH.IAM.title)}`),
    icon: <Security />,
    end_point: FILES_COLLECTION_SETTINGS_ENDPOINT(collectionId, 'IAM'),
  };

  if (isAccessControlEnabled) menuItems.splice(1, 0, iamMenu);

  return menuItems;
};

export const APP_CONFIG = (t) => {
  const translationPrefix = `appConfigurationSection.drawerMenuTitles`;

  const menuItems = [
    {
      title: t(`${translationPrefix}.${APP_CONFIG_PATH.GENERAL.title}`),
      icon: <Settings />,
      end_point: APP_CONFIG_PREFIX + APP_CONFIG_PATH.GENERAL.endpoint,
    },
    {
      title: t(
        `${translationPrefix}.${APP_CONFIG_PATH.EXTERNAL_CHUNKING_CONFIG.title}`,
      ),
      icon: <Http />,
      end_point:
        APP_CONFIG_PREFIX + APP_CONFIG_PATH.EXTERNAL_CHUNKING_CONFIG.endpoint,
    },
    {
      title: t(
        `${translationPrefix}.${APP_CONFIG_PATH.EXTERNAL_EMBEDDING_CONFIG.title}`,
      ),
      icon: <Memory />,
      end_point:
        APP_CONFIG_PREFIX + APP_CONFIG_PATH.EXTERNAL_EMBEDDING_CONFIG.endpoint,
    },
    {
      title: t(
        `${translationPrefix}.${APP_CONFIG_PATH.LANGUAGE_MODELS_CONFIG.title}`,
      ),
      icon: <Tune />,
      end_point:
        APP_CONFIG_PREFIX + APP_CONFIG_PATH.LANGUAGE_MODELS_CONFIG.endpoint,
    },
    {
      title: t(
        `${translationPrefix}.${APP_CONFIG_PATH.STORAGE_CREDENTIAL_CONFIG.title}`,
      ),
      icon: <Key />,
      end_point:
        APP_CONFIG_PREFIX + APP_CONFIG_PATH.STORAGE_CREDENTIAL_CONFIG.endpoint,
    },
  ];

  const marketIntelligenceMenu = {
    title: t(
      `${translationPrefix}.${APP_CONFIG_PATH.MARKET_INTELLIGENCE.title}`,
    ),
    icon: <BarChart />,
    end_point: APP_CONFIG_PREFIX + APP_CONFIG_PATH.MARKET_INTELLIGENCE.endpoint,
  };

  const isMIEnabled = isMarketIntelligenceEnabled;
  if (isMIEnabled) menuItems.push(marketIntelligenceMenu);

  return menuItems;
};

export const ACCESS_CONTROL = (t) => {
  const translationPrefix = (menu) =>
    `accessControlSection.${menu}.drawerTitle`;

  return [
    {
      title: t(`${translationPrefix(ACCESS_CONTROL_PATH.POLICIES.title)}`),
      icon: <Policy />,
      end_point:
        ACCESS_CONTROL_PATH_PREFIX + ACCESS_CONTROL_PATH.POLICIES.endpoint,
    },
    {
      title: t(`${translationPrefix(ACCESS_CONTROL_PATH.GROUPS.title)}`),
      icon: <Group />,
      end_point:
        ACCESS_CONTROL_PATH_PREFIX + ACCESS_CONTROL_PATH.GROUPS.endpoint,
    },
    {
      title: t(`${translationPrefix(ACCESS_CONTROL_PATH.USER_ROLE.title)}`),
      icon: <Shield />,
      end_point:
        ACCESS_CONTROL_PATH_PREFIX + ACCESS_CONTROL_PATH.USER_ROLE.endpoint,
    },
    {
      title: t(`${translationPrefix(ACCESS_CONTROL_PATH.USERS_GROUP.title)}`),
      icon: <Group />,
      end_point:
        ACCESS_CONTROL_PATH_PREFIX + ACCESS_CONTROL_PATH.USERS_GROUP.endpoint,
    },
    {
      title: t(`${translationPrefix(ACCESS_CONTROL_PATH.ROLES.title)}`),
      icon: <Security />,
      end_point:
        ACCESS_CONTROL_PATH_PREFIX + ACCESS_CONTROL_PATH.ROLES.endpoint,
    },
    {
      title: t(`${translationPrefix(ACCESS_CONTROL_PATH.USERS.title)}`),
      icon: <Person />,
      end_point:
        ACCESS_CONTROL_PATH_PREFIX + ACCESS_CONTROL_PATH.USERS.endpoint,
    },
  ];
};

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { triggerFileDownload } from '../../../../utils/functions';

export const paginateDBTableResultEPFactory = (builder) =>
  builder.query({
    query: ({ collectionId, chatId, pageNo, pageSize }) => ({
      url: `file-collections/${collectionId}/search/${chatId}?pageNo=${pageNo}&pageSize=${pageSize}`,
      method: 'GET',
      data: {},
      'Content-Type': 'application/json',
    }),
    providesTags: ['db-table-paginated-result'],
  });

export const downloadDBTableResultEPFactory = (builder) =>
  builder.query({
    query: ({ collectionId, chatId }) => ({
      url: `file-collections/${collectionId}/search/${chatId}?download=true`,
      method: 'GET',
      responseHandler: async (response) => {
        // Extract filename from Content-Disposition header
        let filename = 'results.csv';
        const contentDisposition = response.headers.get('content-disposition');
        if (contentDisposition && contentDisposition.includes('filename=')) {
          const match = contentDisposition.match(/filename="?(.+?)"?$/);
          if (match && match[1]) filename = match[1];
        }

        // Read response as text or blob based on content type
        const contentType = response.headers.get('content-type');
        const isBinary =
          contentType && contentType.includes('application/octet-stream');

        const content = isBinary
          ? await response.blob()
          : await response.text();

        // Trigger file download using the utility function
        triggerFileDownload(
          content,
          filename,
          contentType || 'application/octet-stream', // Default to binary MIME type
        );

        return { filename };
      },
    }),
    providesTags: ['db-table-result'],
  });

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import {
  Box,
  Button,
  Slider,
  styled,
  TextField,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { KeyboardArrowRight } from '@mui/icons-material';

import colors from '../../themes/colors';

export const ToogleContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '20%',
  marginRight: 25,
}));

export const ErrorTextStyled = styled(Box)(({ theme }) => ({
  color: colors.red.R400,
  margin: '5px 0',
}));

export const InputLabel = styled(Box)(({ theme }) => ({
  marginBottom: 5,
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  margin: '30px 0 10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const ButtonStyled = styled(Button)(({ theme }) => ({
  width: '25%',
  marginLeft: 10,
}));

export const LoadingButtonStyled = styled(LoadingButton)(({ theme }) => ({
  width: '25%',
  marginLeft: 10,
}));

export const SliderStyled = styled(Slider)({
  margin: '0 10px',
});

export const LinkContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const LinkTitle = styled(Box)(({ theme }) => ({
  fontSize: 18,
  cursor: 'pointer',
  textDecoration: 'underline',
  color: colors.blue.B400,
}));

export const PageHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const RightIcon = styled(KeyboardArrowRight)(({ theme }) => ({
  margin: '0 5px',
}));

export const TitleText = styled(Box)(({ theme }) => ({
  fontSize: 18,
}));

export const ButtonsContainer2 = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
}));

export const ButtonWithMargin = styled(LoadingButton)(({ theme }) => ({
  marginRight: 0,
}));

export const PageHeader = styled(Box)(({ theme }) => ({
  fontSize: 25,
}));

export const TextFieldStyled = styled(TextField)(({ theme, readOnly }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: readOnly ? theme.palette.grey[200] : 'transparent',
  },
}));

export const PasswordButton = styled(IconButton)(({ theme }) => ({
  marginRight: '1px',
}));

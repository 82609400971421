/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

export const getAll = (builder) =>
  builder.query({
    query: ({ exclude, include, type }) => {
      const params = new URLSearchParams();
      if (include) {
        params.append('include', include.join(','));
      }

      if (exclude) {
        params.append('exclude', exclude.join(','));
      }
      if (type) {
        params.append('type', type);
      }

      return {
        url: `storage-connections?${params}`,
        method: 'GET',
      };
    },
    providesTags: ['storage-credentials'],
  });

export const get = (builder) =>
  builder.query({
    query: ({ storageId }) => {
      return {
        url: `storage-connections/${storageId}`,
        method: 'GET',
      };
    },
    providesTags: ['storage-credentials'],
  });

export const create = (builder) =>
  builder.mutation({
    query: ({ body }) => {
      return {
        url: `storage-connections`,
        method: 'POST',
        body: body,
      };
    },
    invalidatesTags: ['storage-credentials'],
  });

export const update = (builder) =>
  builder.mutation({
    query: ({ update, id }) => {
      return {
        url: `storage-connections/${id}`,
        method: 'PUT',
        body: update,
      };
    },
    invalidatesTags: ['storage-credentials'],
  });

export const updateDefault = (builder) =>
  builder.mutation({
    query: ({ update, id }) => {
      return {
        url: `storage-connections/${id}/default`,
        method: 'PATCH',
        body: update,
      };
    },
    invalidatesTags: ['storage-credentials'],
  });

export const deleteMany = (builder) =>
  builder.mutation({
    query: ({ ids }) => {
      return {
        url: `storage-connections`,
        method: 'DELETE',
        body: { ids },
      };
    },
    invalidatesTags: ['storage-credentials'],
  });

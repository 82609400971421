/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import styled from '@emotion/styled';
import {
  FormControl,
  Typography,
  Select,
  CircularProgress,
} from '@mui/material';

export const StyledFormControl = styled(FormControl)({
  width: '100%',
});

export const DisplayText = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const SelectStyled = styled(Select)(({ theme, isLoading }) => ({
  position: 'relative',
  '& .MuiSelect-icon': {
    display: isLoading ? 'none' : 'inline-block',
  },
}));

export const Loader = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  height: '20px !important',
  width: '20px !important',
  marginRight: '10px',
  top: '8px',
  right: 0,
}));

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { InputField } from '../../settings/fileUploaderPage/fileUploader.page.styled';
import SubmitButtonGroup from './submitButtonGroup';
import { STORAGE_SOURCE } from '../../../constants/dataSources';

const ServiceNowForm = (props) => {
  const [inputValues, setInputValues] = useState({
    name: '',
    storageConnectionId: props.storageConfigId,
    type: props.storageContainer,
    sourceType: STORAGE_SOURCE,
    params: {
      instanceName: '',
      queryParams: '',
    },
  });

  const [validationErrorLabel, setValidationErrorLabel] = useState('');

  const handleInputChange = (fieldName, value) => {
    setInputValues((prevState) => ({
      ...prevState,
      ...(fieldName.includes('.')
        ? { params: { ...prevState.params, [fieldName.split('.')[1]]: value } }
        : { [fieldName]: value }),
    }));
  };

  const clearValues = () => {
    setInputValues({
      name: '',
      storageConnectionId: props.storageConfigId,
      type: props.storageContainer,
      params: {
        instanceName: '',
        queryParams: '',
      },
    });
  };

  useEffect(() => {
    if (props.storageConfigId) {
      setInputValues((prevState) => ({
        ...prevState,
        storageConnectionId: props?.storageConfigId,
      }));
    }
  }, [props.storageConfigId]);

  const { t } = useTranslation();
  return (
    <>
      <InputField
        size="small"
        required
        fullWidth
        name="name"
        label="Name"
        value={inputValues.name}
        onChange={(e) => handleInputChange('name', e.target.value)}
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        required
        fullWidth
        name="instanceName"
        label="Instance Name"
        value={inputValues.params.instanceName}
        onChange={(e) =>
          handleInputChange('params.instanceName', e.target.value)
        }
      />
      <InputField
        sx={{ marginTop: 2 }}
        size="small"
        required
        fullWidth
        name="queryParams"
        label="Query Params"
        value={inputValues.params.queryParams}
        onChange={(e) =>
          handleInputChange('params.queryParams', e.target.value)
        }
      />
      <SubmitButtonGroup
        data={inputValues}
        onClear={clearValues}
        errorMessage={validationErrorLabel}
      />
    </>
  );
};

ServiceNowForm.propTypes = {
  storageContainer: PropTypes.string,
  storageConfigId: PropTypes.string,
};

export default ServiceNowForm;

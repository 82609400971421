/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { useState, useEffect } from 'react';
import { Grid, List, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyGetAllStorageConnectionsQuery } from '../../redux/services/speciphicAsk';
import AppShadowBox from '../../components/app.shadowbox';
import PageContainer from '../../components/pageContainer';
import * as PATHS from '../../constants/path';
import {
  BoxWrapper,
  ButtonLink,
} from '../settings/fileUploaderPage/fileUploader.page.styled';
import DropdownOne from '../../components/dropdownone/dropdownone';
import {
  STORAGE_SOURCE,
  DATABASE_SOURCE,
  AWS_S3_TYPE,
  AZURE_BLOB_TYPE,
  SHAREPOINT_TYPE,
  SERVICENOW_TYPE,
  GCP_GCS_TYPE,
  FTP_TYPE,
  MS_SQL,
  POSTGRE_SQL,
} from '../../constants/dataSources';
import DataSourceForm from './storageForms';
import DatabaseForm from './databaseForms';

const sourceConfigs = [
  { label: 'Storage Source', value: STORAGE_SOURCE },
  { label: 'Database Source', value: DATABASE_SOURCE },
];

const storageConfigs = [
  { label: 'Azure Blob', value: AZURE_BLOB_TYPE },
  { label: 'Sharepoint', value: SHAREPOINT_TYPE },
  { label: 'Service Now', value: SERVICENOW_TYPE },
  { label: 'AWS S3', value: AWS_S3_TYPE },
  { label: 'GCP GCS', value: GCP_GCS_TYPE },
];

const dbConfigs = [
  { label: 'MS SQL', value: MS_SQL },
  { label: 'PostgreSQL', value: POSTGRE_SQL },
];

storageConfigs.sort((a, b) => a.label.localeCompare(b.label));

const AddDataSourcePage = () => {
  const [sourceType, setSourceType] = useState(STORAGE_SOURCE);
  const [storageConfigType, setStorageConfigType] = useState(AZURE_BLOB_TYPE);
  const [dbConfigType, setDBConfigType] = useState(MS_SQL);
  const [storageConfigList, setStorageConfigList] = useState([]);
  const [storageConfigValue, setStorageConfigValue] = useState('');

  const { t } = useTranslation();

  const [
    getStorageConnections,
    {
      data: storageData = [],
      error: storageDataError,
      isError: isStorageDataError,
      isLoading: isStorageDataLoading,
      isSuccess: isStorageDataSuccess,
      isFetching: isStorageDataFetching,
    },
  ] = useLazyGetAllStorageConnectionsQuery();

  const handleSourceConfigChange = (value) => {
    setSourceType(value);
  };

  const handleStorageConfigChange = (value) => {
    setStorageConfigType(value);
    getStorageConnections({
      include: ['name'],
      type: value,
    });
  };

  const handleDBConfigChange = (value) => {
    setDBConfigType(value);
    getStorageConnections({
      include: ['name'],
      type: value,
    });
  };

  const handleStorageConfigNameChange = (value) => {
    setStorageConfigValue(value);
  };

  useEffect(() => {
    if (sourceType === STORAGE_SOURCE) {
      getStorageConnections({
        include: ['name'],
        type: storageConfigType,
      });
    } else {
      getStorageConnections({
        include: ['name'],
        type: dbConfigType,
      });
    }
  }, [sourceType]);

  useEffect(() => {
    if (storageData && isStorageDataSuccess) {
      const formattedStorageData = storageData.map(({ id, name }) => ({
        label: name,
        value: id,
      }));
      setStorageConfigList(formattedStorageData);
    }
  }, [storageData, isStorageDataSuccess]);

  return (
    <PageContainer>
      <BoxWrapper>
        <AppShadowBox>
          <Grid container spacing={2} sx={{ marginBottom: 2 }}>
            <Grid container item xs={12}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h4" color={'primary'}>
                  {t('addDataSourcePage.title')}
                </Typography>
                <Typography variant="body1">
                  {t('addDataSourcePage.subtitle')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ justifyContent: 'flex-end' }}>
                <ButtonLink
                  size="medium"
                  variant="contained"
                  component={Link}
                  to={PATHS.DATA_SOURCES}
                  startIcon={<List />}
                >
                  {t('addDataSourcePage.buttons.dataSourcesButton')}
                </ButtonLink>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item xs={12} sm={6} md={4}>
                <DropdownOne
                  onChange={handleSourceConfigChange}
                  defaultValue={STORAGE_SOURCE}
                  label={t('addDataSourcePage.dropdownLabel.sourceType')}
                  value={sourceType}
                  items={sourceConfigs}
                  sx={{ marginTop: 2 }}
                />
              </Grid>
            </Grid>

            {sourceType === STORAGE_SOURCE && (
              <>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DropdownOne
                      onChange={handleStorageConfigChange}
                      defaultValue={AZURE_BLOB_TYPE}
                      label={t('addDataSourcePage.dropdownLabel.storageType')}
                      value={storageConfigType}
                      items={storageConfigs}
                      sx={{ marginTop: 2 }}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DropdownOne
                      onChange={handleStorageConfigNameChange}
                      label={t(
                        'addDataSourcePage.dropdownLabel.storageTypeName',
                      )}
                      value={storageConfigValue}
                      items={storageConfigList}
                      sx={{ marginTop: 2 }}
                      isLoading={isStorageDataFetching}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DataSourceForm
                      sourceType={storageConfigType}
                      storageConfigId={storageConfigValue}
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {sourceType === DATABASE_SOURCE && (
              <>
                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DropdownOne
                      onChange={handleDBConfigChange}
                      defaultValue={MS_SQL}
                      label={t('addDataSourcePage.dropdownLabel.dbType')}
                      value={dbConfigType}
                      items={dbConfigs}
                      sx={{ marginTop: 2 }}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DatabaseForm
                      dbConfig={dbConfigType}
                      storageData={storageConfigList}
                      isStorageDataLoading={
                        isStorageDataLoading || isStorageDataFetching
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </AppShadowBox>
      </BoxWrapper>
    </PageContainer>
  );
};

export default AddDataSourcePage;

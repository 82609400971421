export default (builder) =>
  builder.mutation({
    query: ({ actions, resourceIds }) => {
      return {
        url: `/permissions/check`,
        method: 'POST',
        body: {
          actions,
          resourceIds,
        },
      };
    },
  });

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * See file LICENSE.txt for full license details.
 */

import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  Paper,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DialogActionsWrapper,
  HeadingGrid,
  ShadowBox,
  StyledDataGrid,
  StyledLoadingButton,
  StyledWrapper,
} from './miReportSources.styled';
import {
  useAddReportSourceMutation,
  useDeleteReportSourceMutation,
  useLazyGetReportSourceQuery,
  useUpdateReportSourceMutation,
} from '../../../redux/services/speciphicAsk';
import DisplayTime from '../../../components/timezone.component';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import EditIconSvg from '../../../assets/edit-icon';
import DeleteIconSvg from '../../../assets/delete-icon';
import AddIconSvg from '../../../assets/add-icon';
import {
  MARKET_INTELLIGENCE_LISTED_FEATURE,
  MARKET_INTELLIGENCE_UNLISTED_FEATURE,
  MI_PARAMS,
} from '../../../constants/marketIntelligence';
import PageContainer from '../../../components/pageContainer';
import { getTheme } from '../../../themes';
import MiNavigationDropdown from '../../../components/marketIntelligence/miNavigationDropdown';
import MiReportSourcePopover from '../../../components/marketIntelligence/miReportSourcePopover';
import MiTabs from '../../../components/marketIntelligence/miTabs';
import { useNavigate, useParams } from 'react-router-dom';
import APPLICATION_TIMEZONE from '../../../constants/timeFormats';
import * as PATHS from '../../../constants/path.js';

const MiReportSourcesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { featureName } = useParams();
  const navigate = useNavigate();
  const [feature, setFeature] = useState(
    featureName === MI_PARAMS.UNLISTED
      ? MARKET_INTELLIGENCE_UNLISTED_FEATURE
      : MARKET_INTELLIGENCE_LISTED_FEATURE,
  );
  const [value, setValue] = useState(
    featureName === MI_PARAMS.UNLISTED ? 1 : 0,
  );
  const [requestReceived, setRequestReceived] = useState(false);
  const [anchorElForNewSource, setAnchorElNewSource] = useState(null);
  const [editorData, setEditorData] = useState({
    companyName: '',
    sourceUrls: [''],
    status: '',
    companyLogoUrl: '',
    entityType: '',
  });
  const [mode, setMode] = useState('add');
  const [modificationId, setModificationId] = useState('');
  const [openDeleteSource, setOpenDeleteSource] = useState(false);

  useEffect(() => {
    setFeature(
      featureName === MI_PARAMS.UNLISTED
        ? MARKET_INTELLIGENCE_UNLISTED_FEATURE
        : MARKET_INTELLIGENCE_LISTED_FEATURE,
    );
    setValue(featureName === MI_PARAMS.UNLISTED ? 1 : 0);
  }, [featureName]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue == 1) {
      setFeature(MARKET_INTELLIGENCE_UNLISTED_FEATURE);
    } else if (newValue == 0) {
      setFeature(MARKET_INTELLIGENCE_LISTED_FEATURE);
    }
    const mappedFeatureName =
      newValue === 1 ? MI_PARAMS.UNLISTED : MI_PARAMS.LISTED;

    navigate(`${PATHS.MI_REPORT_SOURCES}/${mappedFeatureName}`);
  };

  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const [
    getReportSource,
    {
      data: reportSources = [],
      isSuccess: isGetReportSourceSuccess,
      isLoading: isGetReportSourceLoading,
      isError: isGetReportSourceError,
      isFetching: isGetReportSourceFetching,
    },
  ] = useLazyGetReportSourceQuery();

  useEffect(() => {
    getReportSource({
      status: 'approved',
      feature: feature,
    });
  }, [feature]);

  const [
    addReportSource,
    {
      data: newSource,
      isSuccess: isAddNewSourceSuccess,
      isLoading: isAddNewSourceLoading,
      isError: isAddNewSourceError,
    },
  ] = useAddReportSourceMutation();

  useEffect(() => {
    if (isAddNewSourceSuccess) {
      setRequestReceived(true);
      dispatch(
        pushToast({
          message: t('reportSourcesPage.addNew.successToast'),
          severity: 'success',
        }),
      );
    }
  }, [isAddNewSourceSuccess]);

  useEffect(() => {
    if (isAddNewSourceError) {
      dispatch(
        pushToast({
          message: t('reportSourcesPage.addNew.errorToast'),
          severity: 'error',
        }),
      );
    }
  }, [isAddNewSourceError]);

  const [
    updateReportSource,
    {
      data: updatedReportSource,
      isSuccess: isUpdateReportSourceSuccess,
      isLoading: isUpdateReportSourceLoading,
      isError: isUpdateReportSourceError,
    },
  ] = useUpdateReportSourceMutation();

  useEffect(() => {
    if (isUpdateReportSourceSuccess) {
      handleCloseSourcePopover();
      dispatch(
        pushToast({
          message: t('reportSourcesPage.edit.successToast'),
          severity: 'success',
        }),
      );
    }
  }, [isUpdateReportSourceSuccess]);

  useEffect(() => {
    if (isUpdateReportSourceError) {
      dispatch(
        pushToast({
          message: t('reportSourcesPage.edit.errorToast'),
          severity: 'error',
        }),
      );
    }
  }, [isUpdateReportSourceError]);

  const [
    deleteReportSource,
    {
      isError: isDeleteSourceError,
      isLoading: isDeleteSourceLoading,
      isSuccess: isDeleteSourceSuccess,
    },
  ] = useDeleteReportSourceMutation();
  const closeDelete = () => {
    setOpenDeleteSource(false);
  };

  const handleDeleteSource = (id) => {
    setOpenDeleteSource(false);
    deleteReportSource({
      reportSourceId: id,
    });
  };

  useEffect(() => {
    if (isDeleteSourceSuccess) {
      dispatch(
        pushToast({
          message: t('reportSourcesPage.delete.successToast'),
          severity: 'success',
        }),
      );
    }
  }, [isDeleteSourceSuccess]);

  useEffect(() => {
    if (isDeleteSourceError) {
      dispatch(
        pushToast({
          message: t('reportSourcesPage.delete.errorToast'),
          severity: 'error',
        }),
      );
    }
  }, [isDeleteSourceError]);

  const getCompanyName = (props) => {
    return <StyledWrapper>{props.row.companyName}</StyledWrapper>;
  };

  const getStatus = (props) => {
    const status = props.row.status;
    const sentenceCaseStatus =
      status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
    return <StyledWrapper>{sentenceCaseStatus}</StyledWrapper>;
  };

  const getLastUpdatedBy = (props) => {
    return <StyledWrapper>{props.row.lastUpdatedBy}</StyledWrapper>;
  };

  const handleOpenReportSourceEditor = (e, row) => {
    setEditorData({
      companyName: row.companyName,
      sourceUrls: row.sourceUrls,
      status: row.status,
      companyLogoUrl: row?.companyLogoUrl,
      companyStockSymbol: row?.key,
      entityType: row?.entityType,
    });
    setModificationId(row.id);
    setAnchorElNewSource(event.currentTarget);
    setMode('edit');
  };
  const openDeleteSourceDialog = (e, row) => {
    e.stopPropagation();
    setOpenDeleteSource(true);
    setModificationId(row.id);
  };
  const actionColumn = (props) => {
    return (
      <>
        <Tooltip title={'Edit'} arrow>
          <StyledLoadingButton
            onClick={(e) => handleOpenReportSourceEditor(e, props.row)}
          >
            <span>
              <EditIconSvg />
            </span>
          </StyledLoadingButton>
        </Tooltip>
        <Tooltip
          title={t('reportSummaryTopicsPage.dataGrid.actionButton.delete')}
          arrow
        >
          <StyledLoadingButton
            onClick={(e) => openDeleteSourceDialog(e, props.row)}
            loading={modificationId === props.row.id && isDeleteSourceLoading}
          >
            <span>
              <DeleteIconSvg />
            </span>
          </StyledLoadingButton>
        </Tooltip>
      </>
    );
  };

  const DeleteDialogPopup = () => {
    return (
      <Dialog open={openDeleteSource}>
        <DialogContent>
          <Alert severity="warning">
            <AlertTitle>
              {t('reportSourcesPage.dataGrid.deletePopup.heading')}
            </AlertTitle>
            <AlertTitle>
              <strong>
                {t('reportSourcesPage.dataGrid.deletePopup.warning')}
              </strong>
            </AlertTitle>
          </Alert>
        </DialogContent>
        <DialogActionsWrapper>
          <Button
            variant="outlined"
            size="small"
            onClick={closeDelete}
            sx={{ borderColor: 'transparent' }}
          >
            {t('reportSourcesPage.dataGrid.deletePopup.cancelButtonText')}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => handleDeleteSource(modificationId)}
          >
            {t('reportSourcesPage.dataGrid.deletePopup.deleteButtonText')}
          </Button>
        </DialogActionsWrapper>
      </Dialog>
    );
  };

  const columns = [
    {
      field: 'companyName',
      headerName: 'Source Name',
      width: 200,
      headerAlign: 'left',
      align: 'left',
      renderCell: getCompanyName,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      headerAlign: 'left',
      align: 'left',
      renderCell: getStatus,
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Updated By',
      width: 200,
      headerAlign: 'left',
      align: 'left',
      renderCell: getLastUpdatedBy,
    },
    {
      field: 'lastUpdatedDate',
      headerName: 'Updated Date',
      minWidth: 150,
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <StyledWrapper>
          <DisplayTime
            time={params.row?.lastUpdatedDate}
            timezone={APPLICATION_TIMEZONE}
          />
        </StyledWrapper>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: actionColumn,
    },
  ];

  const open = Boolean(anchorElForNewSource);

  const handleClickNewSourcePopover = () => {
    setAnchorElNewSource(event.currentTarget);
    setMode('add');
  };

  const handleCloseSourcePopover = () => {
    setAnchorElNewSource(null);
    setEditorData({ companyName: '', sourceUrls: [''], companyLogoUrl: '' });
    setMode('add');
    setRequestReceived(false);
  };

  const handleNameChange = (e) => {
    setEditorData((prevData) => ({ ...prevData, companyName: e.target.value }));
  };

  const handleStatusChange = (event) => {
    setEditorData((prevData) => ({
      ...prevData,
      status: event.target.value,
    }));
  };
  const handleUrlChange = (index, newValue) => {
    const newsourceUrls = [...editorData.sourceUrls];
    newsourceUrls[index] = newValue;
    setEditorData((prevState) => ({ ...prevState, sourceUrls: newsourceUrls }));
  };

  const handleAddUrl = () => {
    setEditorData((prevState) => ({
      ...prevState,
      sourceUrls: [...prevState.sourceUrls, ''],
    }));
  };

  const handleEntityTypeChange = (event) => {
    setEditorData((prevState) => ({
      ...prevState,
      entityType: event.target.value,
    }));
  };

  const handleAddCompanyStockSymbol = (e) => {
    setEditorData((prevData) => ({
      ...prevData,
      companyStockSymbol: e.target.value,
    }));
  };

  const handleAddCompanyLogo = (e) => {
    setEditorData((prevData) => ({
      ...prevData,
      companyLogoUrl: e.target.value,
    }));
  };
  const handleSubmit = () => {
    if (mode === 'add') {
      addReportSource({
        companyName: editorData.companyName,
        sourceUrls: editorData.sourceUrls,
        companyLogoUrl: editorData.companyLogoUrl,
        companyStockSymbol: editorData.companyStockSymbol,
        feature: feature,
        entityType: editorData.entityType,
      });
    } else if (mode === 'edit') {
      updateReportSource({
        updateData: editorData,
        feature: feature,
        reportSourceId: modificationId,
      });
    }
  };

  const handleDeleteUrl = (index) => {
    const newsourceUrls = [...editorData.sourceUrls];
    newsourceUrls.splice(index, 1);
    setEditorData((prevState) => ({ ...prevState, sourceUrls: newsourceUrls }));
  };

  return (
    <ThemeProvider theme={getTheme('newMi')}>
      <PageContainer background={'secondary'}>
        <ShadowBox>
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item>
              <MiTabs handleTabChange={handleTabChange} value={value} />
            </Grid>
            <Grid item>
              <MiNavigationDropdown />
            </Grid>
          </Grid>

          {isGetReportSourceFetching ? (
            <>
              <LinearProgress />
              <Typography
                variant="h5"
                sx={{ textAlign: 'center', marginTop: 2 }}
              >
                {t('reportSourcesPage.loading')}
              </Typography>
            </>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} p={{ lg: 1 }}>
                <Paper
                  elevation={2}
                  square={false}
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: 2,
                  }}
                >
                  <HeadingGrid item>
                    <Typography variant="h5" sx={{ margin: 2 }}>
                      {t('app.navbar.reportSources') + ' '}
                      {reportSources?.length > 0 && (
                        <>({reportSources?.length})</>
                      )}
                    </Typography>
                    <Button
                      sx={{ borderRadius: '8px', margin: 1 }}
                      size="small"
                      variant="contained"
                      onClick={handleClickNewSourcePopover}
                      startIcon={<AddIconSvg />}
                    >
                      {t('reportSourcesPage.buttons.addNewSource')}
                    </Button>
                  </HeadingGrid>
                  <StyledDataGrid
                    sx={{
                      borderColor: 'white',
                      margin: 2,
                    }}
                    autoHeight
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    pagination
                    paginationMode="client"
                    page={page}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    rows={isGetReportSourceSuccess ? reportSources : []}
                    disableSelectionOnClick
                    disableColumnMenu
                    columns={columns}
                    getRowId={(row) => row.id}
                  />
                </Paper>
              </Grid>
              {/* <Grid
                container
                item
                lg={3}
                xs={12}
                spacing={1}
                justifyContent={'flex-start'}
              >
                <Grid item p={{ lg: 1 }}>
                  <MiReportStats />
                </Grid>
                <Grid item p={{ lg: 1 }}>
                  <MiRelatedEvents />
                </Grid>
              </Grid> */}
            </Grid>
          )}
          <DeleteDialogPopup />

          <MiReportSourcePopover
            open={open}
            initialData={editorData}
            mode={mode}
            anchorEl={anchorElForNewSource}
            handleClose={handleCloseSourcePopover}
            handleNameChange={handleNameChange}
            handleStatusChange={handleStatusChange}
            handleUrlChange={handleUrlChange}
            handleAddUrl={handleAddUrl}
            handleDeleteUrl={handleDeleteUrl}
            onSubmit={handleSubmit}
            requestReceived={requestReceived}
            isLoading={isAddNewSourceLoading || isUpdateReportSourceLoading}
            handleAddCompanyLogo={handleAddCompanyLogo}
            handleAddCompanyStockSymbol={handleAddCompanyStockSymbol}
            marketIntelligenceFeature={feature}
            isAddCompanyStockSymbolRequired={
              feature === MARKET_INTELLIGENCE_UNLISTED_FEATURE ? true : false
            }
            sourceLabel={'Source'}
            handleEntityTypeChange={handleEntityTypeChange}
          />
        </ShadowBox>
      </PageContainer>
    </ThemeProvider>
  );
};

MiReportSourcesPage.propTypes = {
  row: PropTypes.array,
};

export default MiReportSourcesPage;

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import SQLDBForm from './sql';
import PropTypes from 'prop-types';

import { MS_SQL, POSTGRE_SQL } from '../../../constants/dataSources';

const DatabaseForm = ({ dbConfig, storageData, isStorageDataLoading }) => {
  switch (dbConfig) {
    case MS_SQL:
      return (
        <SQLDBForm
          db_type={MS_SQL}
          storageData={storageData}
          isStorageDataLoading={isStorageDataLoading}
        />
      );

    case POSTGRE_SQL:
      return (
        <SQLDBForm
          db_type={POSTGRE_SQL}
          storageData={storageData}
          isStorageDataLoading={isStorageDataLoading}
        />
      );

    default:
      return <>?</>;
  }
};

DatabaseForm.propTypes = {
  dbConfig: PropTypes.string,
  storageData: PropTypes.array,
  isStorageDataLoading: PropTypes.bool,
};

export default DatabaseForm;

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import { InputLabel, MenuItem, Select, FormControl } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { SelectStyled, Loader } from './dropdownone.styled';

const DropdownOne = ({
  items,
  onChange,
  label,
  value,
  defaultValue,
  isLoading = false,
  disabled = false,
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <FormControl fullWidth size="small" disabled={disabled || isLoading}>
      <InputLabel size="small">{label}</InputLabel>
      <SelectStyled
        isLoading={isLoading}
        label={label}
        size="small"
        value={value}
        onChange={handleChange}
        defaultValue={defaultValue}
      >
        {items.map((item) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </SelectStyled>
      {isLoading && <Loader />}
    </FormControl>
  );
};

DropdownOne.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
};

DropdownOne.defaultProps = {
  items: [],
};

export default DropdownOne;

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */

import { Form } from 'formik';
import React, { useEffect } from 'react';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import * as PATHS from '../../../constants/path';
import { InputsContainer } from '../addDataSourcePage.styled';
import AppForm, { AppTextField, FormButtons } from '../../../components/form';
import { DATABASE_SOURCE, MS_SQL } from '../../../constants/dataSources';
import { useAddDataSourceMutation } from '../../../redux/services/speciphicAsk';
import { pushToast } from '../../../redux/reducers/toasts.slice';
import DropdownOne from '../../../components/dropdownone';

const translationJSONPrefix = 'addDataSourcePage';

const defaultDataState = {
  name: '',
  connectionString: '',
  dbSchema: '',
};

const formSchema = (t) => {
  const tErrorMessage = (label, field) =>
    t(`${translationJSONPrefix}.form.${label}.errorMessages.${field}`);

  return object({
    name: string()
      .required(tErrorMessage('name', 'required'))
      .min(3, tErrorMessage('name', 'min'))
      .max(100, tErrorMessage('name', 'max')),
    connectionString: string().required(
      tErrorMessage('connectionString', 'required'),
    ),
    dbSchema: string()
      .optional()
      .test('json', tErrorMessage('dbSchema', 'invalidJSON'), (value) => {
        if (!value) return true;
        try {
          JSON.parse(value);
          return true;
        } catch (error) {
          return false;
        }
      }),
  });
};

const formRenderFunction = (
  { values, errors, touched, dirty, isSubmitting, resetForm, setFieldValue },
  formSending,
  storageData,
  isStorageDataLoading,
) => {
  const { t } = useTranslation();

  const translationPrefix = `${translationJSONPrefix}.form`;

  return (
    <Form>
      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name={'name'}
          label={t(`${translationPrefix}.name.label`)}
          placeholder={t(`${translationPrefix}.name.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
        />
      </InputsContainer>

      <InputsContainer>
        <DropdownOne
          style={{ width: '100%' }}
          name="connectionString"
          label={t(`${translationPrefix}.connectionString.label`)}
          placeholder={t(`${translationPrefix}.connectionString.placeholder`)}
          value={values.connectionString}
          onChange={(value) => setFieldValue('connectionString', value)}
          items={storageData}
          error={touched.connectionString && errors.connectionString}
          isLoading={isStorageDataLoading}
          sx={{ marginTop: 2 }}
        />
      </InputsContainer>

      <InputsContainer>
        <AppTextField
          style={{ width: '100%' }}
          name={'dbSchema'}
          label={t(`${translationPrefix}.dbSchema.label`)}
          placeholder={t(`${translationPrefix}.dbSchema.placeholder`)}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          multiline
          minRows={8}
        />
      </InputsContainer>

      <FormButtons
        resetForm={resetForm}
        dirty={dirty}
        isSubmitting={isSubmitting || formSending}
      />
    </Form>
  );
};

const SQLDBForm = ({ db_type, storageData, isStorageDataLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addDataSource, { isLoading, isError, error, isSuccess, data }] =
    useAddDataSourceMutation();

  useEffect(() => {
    if (isError && error?.data)
      dispatch(
        pushToast({
          message: error?.data?.message,
          severity: 'error',
        }),
      );
  }, [isError]);

  useEffect(() => {
    if (isSuccess && data?.id) {
      dispatch(
        pushToast({
          message: t(`${translationJSONPrefix}.alert.create`),
          severity: 'success',
        }),
      );
      navigate(`${PATHS.DATA_SOURCES}`);
    }
  }, [isSuccess]);

  return (
    <AppForm
      initialValues={defaultDataState}
      validationSchema={formSchema(t)}
      onSubmit={(values, { setSubmitting }) => {
        const dataToSend = {
          name: values?.name,
          sourceType: DATABASE_SOURCE,
          type: db_type,
          storageConnectionId: values?.connectionString,
          params: {
            ...(values?.dbSchema &&
              values.dbSchema !== '' && { dbSchema: values.dbSchema }),
          },
        };

        addDataSource(dataToSend);

        setSubmitting(false);
      }}
      formRenderFunction={(formikState) =>
        formRenderFunction(
          formikState,
          isLoading,
          storageData,
          isStorageDataLoading,
        )
      }
    />
  );
};

SQLDBForm.propTypes = {
  db_type: PropTypes.string,
  storageData: PropTypes.array,
  isStorageDataLoading: PropTypes.bool,
};

export default SQLDBForm;

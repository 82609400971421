import React, { useMemo } from 'react';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useLazyGetStorageConnectionQuery,
  useUpdateStorageConnectionMutation,
  useCreateStorageConnectionMutation,
} from '../../../redux/services/speciphicAsk';
import { SECTIONS } from '../../../constants/drawer';
import { APP_CONFIGURATION } from '../../../constants/path';
import Loader from '../../../components/loader';
import PageContainer from '../../../components/pageContainer';
import { AppTextField, FieldLabel } from '../../../components/form';
import {
  ButtonsContainer,
  ButtonStyled,
  LoadingButtonStyled,
} from './styled.page';
import { Restore } from '@mui/icons-material';
import AddPageHeader from '../../../components/form/addPageHeader.component';
import { STORAGE_CREDENTIAL_CONFIG } from '../../../constants/appConfigurationPaths';
import { FormContainer, InputsContainer } from '../styled';
import { Select, MenuItem, FormControl, Box } from '@mui/material';
import { AlertStyled } from '../../settings/filesCollectionSettingsPage/styled';
import { useTranslation } from 'react-i18next';
import { Regions as AWS_REGIONS } from '../../../constants/awsRegions';
import DropdownOne from '../../../components/dropdownone';
import {
  AWS_S3_TYPE,
  AZURE_BLOB_TYPE,
  GCP_GCS_TYPE,
} from '../../../constants/dataSources';
import { Regions } from '../../../constants/gcpRegions';
const mainPageRoute = `${APP_CONFIGURATION}${STORAGE_CREDENTIAL_CONFIG.endpoint}`;
const translationJSONPrefix = 'appConfigurationSection.storageCredentialConfig';

const PROVIDER_CONFIG = {
  AZURE_BLOB: {
    label: 'Azure Blob',
    fields: ['sourceType', 'type', 'name', 'accountName', 'accessKey'],
  },
  AWS_S3: {
    label: 'AWS S3',
    fields: ['sourceType', 'type', 'name', 'accessKey', 'secretKey', 'region'],
  },
  GCP_GCS: {
    label: 'GCP Storage',
    fields: [
      'sourceType',
      'type',
      'name',
      'projectId',
      'clientEmail',
      'privateKey',
      'region',
    ],
  },
  SHARE_POINT: {
    label: 'Share Point',
    fields: [
      'sourceType',
      'type',
      'name',
      'accessTokenUrl',
      'authTenantId',
      'authClientId',
      'authClientSecret',
      'authScope',
    ],
  },
  SERVICENOW: {
    label: 'ServiceNow',
    fields: ['sourceType', 'type', 'name', 'username', 'password'],
  },
  MSSQL: {
    label: 'MS SQL',
    fields: ['sourceType', 'type', 'name', 'connectionString', 'dbSchema'],
  },
  POSTGRES: {
    label: 'PostgreSQL',
    fields: ['sourceType', 'type', 'name', 'connectionString', 'dbSchema'],
  },
};

const SOURCE_TYPE = [
  {
    label: 'Storage',
    value: 'STORAGE',
  },
  {
    label: 'Database',
    value: 'DATABASE',
  },
];

const StorageCredentialsAddPage = () => {
  const [searchParams] = useSearchParams();
  const [currentProvider, setCurrentProvider] = useState(null);
  const [formValues, setFormValues] = useState({ params: {} });
  const [initialValues, setInitialValues] = useState({ params: {} });
  const [sourceType, setSourceType] = useState('STORAGE');
  const [providers, setProviders] = useState([]);
  const [providerType, setProviderType] = useState('AZURE_BLOB');
  const [isDrawerOpen, setDrawerOpen] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [showAlert, setShowAlert] = useState({
    formUpdateError: false,
    formUpdateSuccess: false,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const [
    getStorageConnection,
    {
      data: storageData = [],
      error: storageDataError,
      isError: isStorageDataError,
      isLoading: isStorageDataLoading,
      isSuccess: isStorageDataSuccess,
      isFetching: isStorageDataFetching,
    },
  ] = useLazyGetStorageConnectionQuery();

  const [
    updateStorageConnection,
    {
      data: storageUpdatedData = [],
      error: storageDataUpdateError,
      isError: isStorageDataUpdateError,
      isLoading: isStorageDataUpdateLoading,
      isSuccess: isStorageDataUpdateSuccess,
      isFetching: isStorageDataUpdateFetching,
    },
  ] = useUpdateStorageConnectionMutation();

  const [
    createStorageConnection,
    {
      data: storage = [],
      error: storageError,
      isError: isStorageError,
      isLoading: isStorageLoading,
      isSuccess: isStorageSuccess,
      isFetching: isStorageFetching,
    },
  ] = useCreateStorageConnectionMutation();

  useEffect(() => {
    const storageConnectionId = searchParams.get('id');

    if (storageConnectionId) {
      setIsEdit(true);
      getStorageConnection({ storageId: storageConnectionId });
    } else {
      setIsEdit(false);
      setCurrentProvider(null);
      setFormValues({ params: {} });
      setInitialValues({ params: {} });
    }
  }, []);

  useEffect(() => {
    if (storageData?.type) {
      const providerConfig = PROVIDER_CONFIG[storageData.type];
      setCurrentProvider(providerConfig);
      if (providerConfig) {
        const initialValues = { params: {} };
        providerConfig.fields.forEach((field) => {
          if (['sourceType', 'type', 'name'].includes(field)) {
            initialValues[field] = storageData[field] || '';
          } else {
            initialValues.params[field] = storageData.params[field] || '';
          }
        });
        setFormValues(initialValues);
        setInitialValues(initialValues);
      }
    }
  }, [storageData]);

  useEffect(() => {
    const providerConfig = PROVIDER_CONFIG[providerType];
    setCurrentProvider(providerConfig);
    if (providerConfig) {
      const initialValues = { params: {} };
      providerConfig.fields.forEach((field) => {
        if (isEdit || !['sourceType', 'type'].includes(field)) {
          if (['sourceType', 'type', 'name'].includes(field)) {
            initialValues[field] = '';
          } else if (field === 'region') {
            initialValues.params[field] =
              providerType === AWS_S3_TYPE
                ? AWS_REGIONS[0].value
                : Regions[0].value;
          } else {
            initialValues.params[field] = '';
          }
        }
      });
      setFormValues(initialValues);
    }
  }, [providerType]);

  const handleSourceChange = (e) => {
    setSourceType(e.target.value);
  };

  const handleStorageProviderChange = (e) => {
    setProviderType(e.target.value);
  };

  useEffect(() => {
    if (sourceType === 'STORAGE') {
      const storageProviders = [
        'AZURE_BLOB',
        'AWS_S3',
        'GCP_GCS',
        'SHARE_POINT',
        'SERVICENOW',
      ];
      setProviders(storageProviders);
      setProviderType(storageProviders[0]);
    } else if (sourceType === 'DATABASE') {
      const databaseProviders = ['MSSQL', 'POSTGRES'];
      setProviders(databaseProviders);
      setProviderType(databaseProviders[0]);
    } else {
      setProviders([]);
      setProviderType(null);
    }
  }, [sourceType]);

  const handleChange = (field, value) => {
    setFormValues((prev) => {
      if (['sourceType', 'type', 'name'].includes(field)) {
        return { ...prev, [field]: value };
      } else {
        return {
          ...prev,
          params: {
            ...prev.params,
            [field]: value,
          },
        };
      }
    });
  };

  const closeAlert = () => {
    const tempAlert = { ...showAlert };
    Object.keys(tempAlert).forEach((a) => (tempAlert[a] = false));
    setShowAlert(tempAlert);
    setErrorMessage('');
  };

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isStorageDataUpdateError || isStorageError) {
      const tempErrorMessage = storageDataUpdateError?.data?.message;
      t(`${translationJSONPrefix}.form.errorAlertLabel`);
      setErrorMessage(tempErrorMessage);
      setTimeout(() => {
        tempAlert.formUpdateError = true;
        tempAlert.formUpdateSuccess = false;
        setShowAlert(tempAlert);
      }, 500);
    }
  }, [isStorageDataUpdateError, isStorageError]);

  useEffect(() => {
    const tempAlert = { ...showAlert };
    if (isStorageDataUpdateSuccess || isStorageSuccess) {
      tempAlert.formUpdateSuccess = true;
      tempAlert.formUpdateError = false;
      setShowAlert(tempAlert);
    }
  }, [isStorageDataUpdateSuccess, isStorageSuccess]);

  useEffect(() => {
    if (showAlert.formUpdateError || showAlert.formUpdateSuccess)
      setTimeout(closeAlert, 5000);
  }, [showAlert.formUpdateError, showAlert.formUpdateSuccess]);

  const isDirty = JSON.stringify(formValues) !== JSON.stringify(initialValues);

  const handleSave = () => {
    const storageConnectionId = searchParams.get('id');
    if (storageConnectionId) {
      updateStorageConnection({
        update: formValues,
        id: storageConnectionId,
      });
    } else {
      const body = {
        ...formValues,
        sourceType,
        type: providerType,
      };

      createStorageConnection({
        body,
      });
    }
  };

  const formatFieldName = (field) => {
    return field
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/^./, (str) => str.toUpperCase());
  };

  const resetForm = () => {
    setFormValues(initialValues);
  };

  const regionItems = useMemo(() => {
    if (providerType === AWS_S3_TYPE || formValues['type'] === AWS_S3_TYPE) {
      return AWS_REGIONS;
    }
    if (providerType === GCP_GCS_TYPE || formValues['type'] === GCP_GCS_TYPE) {
      return Regions;
    }
  }, [providerType, formValues]);

  return (
    <PageContainer
      isDrawerOpen={(res) => setDrawerOpen(res)}
      drawer={SECTIONS.APP_CONFIGURATION}
    >
      <AddPageHeader
        routes={[
          {
            title: 'Storage Credentials',
            endpoint: mainPageRoute,
          },
        ]}
        isEdit={isEdit ? true : false}
      />
      <FormContainer>
        {isStorageDataLoading ? (
          <Loader label={t(`${translationJSONPrefix}.loadingCredentials`)} />
        ) : (
          <>
            <AlertStyled
              onClose={closeAlert}
              severity={showAlert.formUpdateError ? 'error' : 'success'}
              style={{
                marginTop:
                  showAlert.formUpdateError || showAlert.formUpdateSuccess
                    ? 10
                    : -60,
              }}
            >
              {showAlert.formUpdateSuccess &&
                (isEdit
                  ? t(`${translationJSONPrefix}.form.updatedAlertLabel`)
                  : t(`${translationJSONPrefix}.form.createdAlertLabel`))}
              {showAlert.formUpdateError &&
                (errorMessage ||
                  t(`${translationJSONPrefix}.form.errorAlertLabel`))}
            </AlertStyled>
            {isEdit == false && (
              <>
                <InputsContainer>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <FieldLabel label="Source Type"></FieldLabel>
                    <Select value={sourceType} onChange={handleSourceChange}>
                      {SOURCE_TYPE?.map((field) => (
                        <MenuItem key={field.value} value={field.value}>
                          {field.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </InputsContainer>
                <InputsContainer>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <FieldLabel label="Storage Type"></FieldLabel>
                    <Select
                      value={providerType}
                      onChange={handleStorageProviderChange}
                    >
                      {providers?.map((field) => (
                        <MenuItem
                          key={PROVIDER_CONFIG[field]?.label}
                          value={field}
                        >
                          {PROVIDER_CONFIG[field]?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </InputsContainer>
              </>
            )}

            {currentProvider &&
              currentProvider.fields
                .filter(
                  (field) => isEdit || !['sourceType', 'type'].includes(field),
                )
                .map((field) => (
                  <>
                    {field === 'region' ? (
                      <Box sx={{ marginTop: 2 }}>
                        <DropdownOne
                          onChange={(e) => handleChange(field, e)}
                          defaultValue={
                            providerType === AWS_S3_TYPE
                              ? AWS_REGIONS[0].value
                              : Regions[0].value
                          }
                          label={formatFieldName(field)}
                          value={
                            ['sourceType', 'type', 'name'].includes(field)
                              ? formValues[field]
                              : formValues.params[field] || ''
                          }
                          items={regionItems}
                        ></DropdownOne>
                      </Box>
                    ) : (
                      <AppTextField
                        key={field}
                        label={formatFieldName(field)}
                        name={field}
                        value={
                          ['sourceType', 'type', 'name'].includes(field)
                            ? formValues[field]
                            : formValues.params[field] || ''
                        }
                        onChange={(e) => handleChange(field, e.target.value)}
                        readOnly={
                          isEdit && ['sourceType', 'type'].includes(field)
                        }
                        multiline={field === 'dbSchema'}
                        minRows={8}
                        isPasswordField={
                          !!formValues.params[field] && field !== 'dbSchema'
                        }
                      />
                    )}
                  </>
                ))}

            <ButtonsContainer>
              <ButtonStyled
                variant="outlined"
                startIcon={<Restore />}
                onClick={resetForm}
                disabled={
                  !isDirty || isStorageDataUpdateLoading || isStorageLoading
                }
              >
                {t(`formComponent.buttons.reset`)}
              </ButtonStyled>
              <LoadingButtonStyled
                variant="contained"
                type="submit"
                disabled={!isDirty || isStorageDataUpdateLoading}
                loading={isStorageDataUpdateLoading || isStorageLoading}
                onClick={handleSave}
              >
                {t(`formComponent.buttons.save`)}
              </LoadingButtonStyled>
            </ButtonsContainer>
          </>
        )}
      </FormContainer>
    </PageContainer>
  );
};

export default StorageCredentialsAddPage;

export const STORAGE_SOURCE = 'STORAGE';
export const DATABASE_SOURCE = 'DATABASE';
export const POSTGRE_SQL = 'POSTGRES';

// Storage configs
export const AZURE_BLOB_TYPE = 'AZURE_BLOB';
export const AWS_S3_TYPE = 'AWS_S3';
export const FTP_TYPE = 'FTP';
export const SHAREPOINT_TYPE = 'SHARE_POINT';
export const SERVICENOW_TYPE = 'SERVICENOW';
export const GCP_GCS_TYPE = 'GCP_GCS';

// Database configs
export const MS_SQL = 'MSSQL';

/* Copyright Flexday Solutions LLC, Inc - All Rights Reserved

 * Unauthorized copying of this file, via any medium is strictly prohibited

 * Proprietary and confidential

 * See file LICENSE.txt for full license details.

 */
import {
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Popover,
  Button,
  OutlinedInput,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import {
  StyledFormControl,
  DisplayText,
  StyledBox,
  DropdownTextWrapper,
  AddBox,
} from './dropdown.styled';
import PropTypes from 'prop-types';

const Dropdown = ({
  items,
  onChange,
  value,
  noOptionsMessage,
  placeholder,
  isLoading,
  loadingMessage,
  searchEnginePlaceholder,
  label,
  fcName,
  onFCNameChange,
  fCNameValidFetching,
  isFCNameValid,
  onPopoverConfirm,
  onAddCollectionLoading,
  setFcName,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);

  const handleChange = (e) => {
    onChange(e.target.value);
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(anchorRef.current);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleFCNameChange = (e) => {
    const updatedValue = e.target.value;
    onFCNameChange(updatedValue);
  };

  const handleAddNewSelect = () => {
    handlePopoverOpen();
    onFCNameChange(fcName);
  };

  useEffect(() => {
    if (!onAddCollectionLoading) {
      handlePopoverClose();
    }
  }, [onAddCollectionLoading]);

  const itemsWithOrder = items
    .filter((item) => typeof item.displayOrder !== 'undefined')
    .sort((a, b) => a.displayOrder - b.displayOrder);

  // Then, filter items without displayOrder
  const itemsWithoutOrder = items.filter(
    (item) => typeof item.displayOrder === 'undefined',
  );

  // Concatenate both arrays, so items without displayOrder will be added at the end
  const sortedItems = itemsWithOrder.concat(itemsWithoutOrder);
  return (
    <StyledFormControl
      size="small"
      sx={{
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            border: 'none',
          },
      }}
    >
      <InputLabel
        // id="select-label"
        shrink={!isLoading}
        sx={{ width: 'calc(100% - 48px)' }}
      >
        {isLoading ? (
          <StyledBox>
            <Typography variant="body1">{loadingMessage}</Typography>
            <CircularProgress size="1.3rem" />
          </StyledBox>
        ) : (
          label && `${placeholder}`
        )}
      </InputLabel>

      <Select
        labelId="select-label"
        // label={placeholder}
        value={value}
        renderValue={(value) => {
          const currentItem = items.find((item) => item.value === value);
          return (
            <DropdownTextWrapper>
              <DisplayText
                variant="body1"
                sx={{ fontSize: '14px', fontWeight: 600, pb: 0 }}
              >
                {currentItem ? currentItem?.label : noOptionsMessage}
              </DisplayText>
              <Typography
                variant="caption text"
                display={{ xs: 'none', sm: 'none', md: 'block' }}
                sx={{ fontSize: '14px', fontWeight: 300, lineHeight: '14px' }}
              >
                {currentItem ? searchEnginePlaceholder : ''}
              </Typography>
            </DropdownTextWrapper>
          );
        }}
        disabled={items.length === 0}
        displayEmpty={!isLoading}
        onChange={handleChange}
        sx={{
          width: '100%',
          background: (theme) => theme.palette.dropdown.primary,
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          border: 'none',

          height: '44px',
        }}
        // input={
        //   isLoading ? undefined : <OutlinedInput notched label={placeholder} />
        // }
        MenuProps={{
          PaperProps: { sx: { width: '250px' } },
        }}
        IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
      >
        <MenuItem onClick={handleAddNewSelect} sx={{ fontWeight: 'bold' }}>
          <AddBox>
            <AddIcon sx={{ marginLeft: '-3px', marginRight: '3px' }} />
            <Typography variant="body1" fontWeight="bold">
              Add New Collection
            </Typography>
          </AddBox>
        </MenuItem>
        {sortedItems.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            <Box>
              <Typography variant="body1">{item.label}</Typography>
              <Typography variant="body2" sx={{ whiteSpace: 'break-spaces' }}>
                {item.description}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Select>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '8px',
            },
          },
        }}
      >
        <List sx={{ width: '270px' }}>
          <ListItem>
            <ListItemText primary="Create new file collection"></ListItemText>
          </ListItem>
          <ListItem>
            <FormControl fullWidth size="small">
              <InputLabel>File Collection</InputLabel>
              <OutlinedInput
                required
                label="File Collection"
                size="small"
                value={fcName}
                onChange={handleFCNameChange}
                fullWidth
                endAdornment={
                  <InputAdornment position="end">
                    {fCNameValidFetching ? (
                      <CircularProgress
                        style={{
                          height: '15px',
                          width: '15px',
                        }}
                      />
                    ) : (
                      <>
                        {isFCNameValid?.valid == true ? (
                          <CheckCircleIcon
                            color="success"
                            sx={{ marginRight: '-5px' }}
                          />
                        ) : (
                          <Tooltip title={isFCNameValid?.message} arrow>
                            <ErrorIcon
                              color="error"
                              sx={{ marginRight: '-5px' }}
                            />
                          </Tooltip>
                        )}
                      </>
                    )}
                  </InputAdornment>
                }
              ></OutlinedInput>
            </FormControl>
          </ListItem>
          <ListItem
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <LoadingButton
              variant="contained"
              disabled={isFCNameValid?.valid == false}
              size="small"
              onClick={onPopoverConfirm}
              loading={onAddCollectionLoading}
            >
              Create
            </LoadingButton>
            <Button
              variant="contained"
              onClick={handlePopoverClose}
              size="small"
              disabled={onAddCollectionLoading}
            >
              Cancel
            </Button>
          </ListItem>
        </List>
      </Popover>
      <Box ref={anchorRef}></Box>
    </StyledFormControl>
  );
};

Dropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  value: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  searchEnginePlaceholder: PropTypes.string,
  label: PropTypes.bool,
  onFCNameChange: PropTypes.func,
  fCNameValidFetching: PropTypes.bool,
  isFCNameValid: PropTypes.object,
  onPopoverConfirm: PropTypes.func,
  onAddCollectionLoading: PropTypes.bool,
  fcName: PropTypes.string,
  setFcName: PropTypes.func,
};

Dropdown.defaultProps = {
  items: [],
  isLoading: false,
};

export default Dropdown;
